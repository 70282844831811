import { GLOSSARY_FIELDS_FRAGMENT } from "./base/graphql";

export const GET_BUILD_GLOSSARIES = /* GraphQL */ `
  ${GLOSSARY_FIELDS_FRAGMENT}
  query getBuildGlossaries($input: GetBuildGlossariesInput!) {
    getBuildGlossaries(input: $input) {
      data {
        ...GlossaryFields
      }
      nextToken
    }
  }
`;
export const GET_BUILD_GLOSSARY = /* GraphQL */ `
  ${GLOSSARY_FIELDS_FRAGMENT}
  query getBuildGlossary($input: GetBuildGlossaryInput!) {
    getBuildGlossary(input: $input) {
      ...GlossaryFields
    }
  }
`;
