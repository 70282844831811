import { CONVERSATION_FIELDS_FRAGMENT } from './base/graphql';

export const UPDATE_CONVERSATION_SUB = /* GraphQL */ `
  ${CONVERSATION_FIELDS_FRAGMENT}
  subscription onUpdateConversation($tenantId: String!, $contactId: String!) {
    onUpdateConversation(tenantId: $tenantId, contactId: $contactId) {
      ...ConversationFields
    }
  }
`;
