import _ from "lodash";
import { v4 } from "uuid";
import React, { useMemo, useContext, createContext } from "react";

import Message, { IMessage } from "../../components/message";

export type MessageContextType = {
    newMessage: (type: IMessage["type"], text: IMessage["text"], duration?: IMessage["duration"], group?: IMessage["group"], dismissible?: IMessage["dismissible"], loud?: IMessage["loud"]) => IMessage["id"],
    dismissMessageGroup: (group: IMessage["group"]) => void,
};

// ============== CONTEXT ==============
export const MessageContext = createContext({} as MessageContextType);

// ============== PROVIDER ==============
type Props = {
    children: React.ReactNode;
    wrapperProps: any;
};
export function MessageProvider({ children }: Props) {
    const [messages, setMessages] = React.useState<IMessage[]>([]);

    const newMessage: MessageContextType["newMessage"] = (type, text, duration = 3000, group = "", dismissible = true, loud = false) => {
        // HH: Add the message to the messages array
        const id = v4();
        // HH: If a message with the same group already exists, remove it first
        let currentMessages = messages;
        if (group) {
            currentMessages = _.filter(messages, (m) => m.group !== group);
        }
        const newMessage: IMessage = {
            text,
            type,
            id,
            duration,
            loud,
            createdOn: new Date(Date.now()).toISOString(),
            group,
            dismissible
        }
        setMessages([...currentMessages, newMessage]);
        return id;
    };

    const dismissMessageGroup: MessageContextType["dismissMessageGroup"] = (group) => {
        setMessages(_.filter(messages, (m) => m.group !== group));
    }

    const memoizedValue = useMemo(() => ({ newMessage, dismissMessageGroup } as MessageContextType), [newMessage, dismissMessageGroup]);

    return (
        <MessageContext.Provider value={memoizedValue}>
            {children}

            {/* Messages  */}
            <>
                {messages?.map((message, index) => {
                    return <Message setMessages={setMessages} messages={messages} message={message} messageIndex={index} />
                })}
            </>

        </MessageContext.Provider>
    );
}

// ============== HOOK ==============
export const useMessageContext = () => {
    const context = useContext(MessageContext);
    if (!context)
        throw new Error("useMessageContext context must be use inside MessageProvider");
    return context;
};