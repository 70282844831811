import axios from 'axios';

import { SERVICE_DESK_API } from '../../config-global';

const ServiceDeskClient = axios.create({ baseURL: SERVICE_DESK_API });

ServiceDeskClient.interceptors.response.use(
  (res) => res,
  (error) => {
    console.error('ServiceDeskClient', 'interceptor', error);
    Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

export default ServiceDeskClient;