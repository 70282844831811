import { PAPER_FIELDS_FRAGMENT } from "./base/graphql";

export const GET_BUILD_PAPERS = /* GraphQL */ `
  ${PAPER_FIELDS_FRAGMENT}
  query getBuildPapers($input: GetBuildPapersInput!) {
    getBuildPapers(input: $input) {
      data {
        ...PaperFields
      }
      nextToken
    }
  }
`;
export const GET_BUILD_PAPER = /* GraphQL */ `
  ${PAPER_FIELDS_FRAGMENT}
  query getBuildPaper($input: GetBuildPaperInput!) {
    getBuildPaper(input: $input) {
      ...PaperFields
    }
  }
`;
