// TODO: HH:  Remove correctValue from answer when the paper type is certification

import { STUDENT_EXAM_FIELDS_FRAGMENT } from "../../studentExam/base/graphql";

export const STUDENT_EXAM_PAPER_FIELDS_FRAGMENT = /* GraphQL */ `
  fragment StudentExamPaperFields on StudentExamPaper {
    id
    studentExamId
    paperId
    partnerId
    name
    type
    status
    score
    scoreThresholds {
      green
      amber
    }
    totalScore
    startedOn
    time
    completedOn
    moduleName
    answers {
      id
      studentExamPaperId
      question {
        id
        tenantId
        name
        type
        text
        options {
          label
          value
        }
        info
        answer
        tolerance
        tips
        showWorkings
        showUploads
        score
      }
      questionType {
        id
        code
        name
        structure {
          type
          label
          optionsIndex
          scrambleOrder
          rowWidth
          enforcedOptionCount
          enforcedOptions {
            label
            value
          }
          generateOptions {
            type
            from
            to
            format
          }
        }
        display
        defaultValue
      }
      paperQuestion {
        id
        paperId
        questionId
        name
        sort
        previousQuestion
      }
      value
      correctValue
      text
      workings
      uploads {
        id
        name
        title
        url
      }
      isCorrect
      score
      isAutoMarked
      instructorComments
      instructorTips
      isLocked
      isToBeReset
      isReset
      isAutoSubmitted
      questionId
      studentExamId
    }
    cb
    sb
    co
    so
  }
`;
export const CREATE_STUDENT_EXAM_PAPER = /* GraphQL */ `
  ${STUDENT_EXAM_PAPER_FIELDS_FRAGMENT}
  mutation CreateStudentExamPaper($input: CreateStudentExamPaperInput!) {
    createStudentExamPaper(input: $input) {
      ...StudentExamPaperFields
    }
  }
`;
export const GET_STUDENT_EXAM_PAPER = /* GraphQL */ `
  ${STUDENT_EXAM_PAPER_FIELDS_FRAGMENT}
  query GetStudentExamPaper($input: GetStudentExamPaperInput!) {
    getStudentExamPaper(input: $input) {
      ...StudentExamPaperFields
    }
  }
`;
export const GET_STUDENT_EXAM_PAPERS_BY_STUDENT_EXAM_ID = /* GraphQL */ `
  ${STUDENT_EXAM_PAPER_FIELDS_FRAGMENT}
  query GetStudentExamPapersByStudentExamId(
    $input: GetStudentExamPaperByStudentExamIdInput!
  ) {
    getStudentExamPapersByStudentExamId(input: $input) {
      data {
        ...StudentExamPaperFields
      }
    }
  }
`;
export const GET_STUDENT_EXAM_PAPERS_BY_GSI1 = /* GraphQL */ `
  ${STUDENT_EXAM_PAPER_FIELDS_FRAGMENT}
  query GetStudentExamPapersByGSI1($input: GetStudentExamPapersInput!) {
    getStudentExamPapersByGSI1(input: $input) {
      data {
        ...StudentExamPaperFields
      }
      nextToken
    }
  }
`;
export const GET_STUDENT_EXAM_PAPERS_BY_GSI2 = /* GraphQL */ `
  ${STUDENT_EXAM_PAPER_FIELDS_FRAGMENT}
  query GetStudentExamPapersByGSI2($input: GetStudentExamPapersInput!) {
    getStudentExamPapersByGSI2(input: $input) {
      data {
        ...StudentExamPaperFields
      }
      nextToken
    }
  }
`;
export const GET_STUDENT_EXAM_PAPER_WITH = /* GraphQL */ `
  ${STUDENT_EXAM_PAPER_FIELDS_FRAGMENT}
  query GetStudentExamPaperWith($input: GetStudentExamPaperInput!) {
    getStudentExamPaperWith(input: $input) {
      ...StudentExamPaperFields
    }
  }
`;
export const UPDATE_STUDENT_EXAM_PAPER = /* GraphQL */ `
  ${STUDENT_EXAM_PAPER_FIELDS_FRAGMENT}
  mutation UpdateStudentExamPaper($input: UpdateStudentExamPaperInput!) {
    updateStudentExamPaper(input: $input) {
      ...StudentExamPaperFields
    }
  }
`;
export const COMPLETE_STUDENT_EXAM_PAPER = /* GraphQL */ `
  ${STUDENT_EXAM_PAPER_FIELDS_FRAGMENT}
  mutation CompleteStudentExamPaper($input: UpdateStudentExamPaperInput!) {
    completeStudentExamPaper(input: $input) {
      ...StudentExamPaperFields
    }
  }
`;
export const LOAD_STUDENT_EXAM_PAPER = /* GraphQL */ `
  ${STUDENT_EXAM_PAPER_FIELDS_FRAGMENT}
  ${STUDENT_EXAM_FIELDS_FRAGMENT}
  mutation LoadStudentExamPaper($input: LoadStudentExamPaperInput!) {
    loadStudentExamPaper(input: $input) {
      studentExam {
        ...StudentExamFields
      }
      studentExamPaper {
        ...StudentExamPaperFields
      }
    }
  }
`;
export const DELETE_STUDENT_EXAM_PAPER = /* GraphQL */ `
  ${STUDENT_EXAM_PAPER_FIELDS_FRAGMENT}
  mutation DeleteStudentExamPaper($input: DeleteStudentExamPaperInput!) {
    deleteStudentExamPaper(input: $input) {
      ...StudentExamPaperFields
    }
  }
`;
