import Mailbox from "./mailbox/index";
import Message from "./message/index";
import Conversation from "./conversation/index";

const ServiceDeskService = {
    Mailbox,
    Message,
    Conversation
};

export default ServiceDeskService;