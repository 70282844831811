import { COURSE_FIELDS_FRAGMENT } from "./base/graphql";

export const GET_BUILD_COURSES = /* GraphQL */ `
  ${COURSE_FIELDS_FRAGMENT}
  query getBuildCourses($input: GetBuildCoursesInput!) {
    getBuildCourses(input: $input) {
      data {
        ...CourseFields
      }
      nextToken
    }
  }
`;
export const GET_BUILD_COURSE = /* GraphQL */ `
  ${COURSE_FIELDS_FRAGMENT}
  query getBuildCourse($input: GetBuildCourseInput!) {
    getBuildCourse(input: $input) {
      ...CourseFields
    }
  }
`;

export const GET_BUILD_COURSE_RESOURCES = /* GraphQL */ `
  query getBuildCourseResources($input: GetBuildCourseResourcesInput!) {
    getBuildCourseResources(input: $input) {
      data {
        id
        parentId
        resourceId
        name
        slug
        sort
        status
        courseId
        cb
        sb
        co
        so
      }
      nextToken
    }
  }
`;
