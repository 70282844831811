import Color from 'color'
import React, { useMemo } from 'react'

import Card, { CardProps } from './card'
import UTIcon, { UTIconProps } from './icons'
import { usePartnerContext } from '../context/partner'
import { PartnerColors } from '../context/partner/types'

type Props = {
    children?: React.ReactNode | string
    icon?: UTIconProps["type"]
    iconColor?: keyof PartnerColors
    iconSize?: number
    color?: CardProps["color"]
    heading?: string
    right?: React.ReactNode
}

export default function Alert({ children, icon, iconColor: iconColorOverride, iconSize = 26, heading, color, right }: Props) {

    const { colors } = usePartnerContext();

    const colorStyle: any = useMemo(() => {
        let borderColor = Color(colors.gray1).darken(0.2).hex();
        let backgroundColor = colors.white;
        let headerColor = {
            backgroundColor: colors.gray1,
            color: colors.textColor,
            borderColor: Color(colors.gray1).darken(0.2).hex()
        };
        let bodyColor = colors.textColor;
        let headingBorderColor = colors.gray6;

        if (color === "grey") {
            borderColor = Color(colors.gray1).darken(0.2).hex()
            headerColor = {
                backgroundColor: colors.gray1,
                color: colors.textColor,
                borderColor: Color(colors.gray1).darken(0.2).hex()
            };
        }

        if (color === "grey2") {
            backgroundColor = colors.gray1
        }

        if (color === "info") {
            borderColor = Color(colors.info).darken(0.2).hex();
            headerColor = {
                backgroundColor: colors.info,
                color: colors.textColor,
                borderColor: Color(colors.info).darken(0.2).hex()
            };
        }

        if (color === "info2") {
            borderColor = Color(colors.info).darken(0.2).hex();
            backgroundColor = Color(colors.info).lighten(0).hex();
            headerColor = {
                backgroundColor: Color(colors.info).hex(),
                color: colors.textColor,
                borderColor: Color(colors.info).darken(0.2).hex()
            };
            bodyColor = Color(colors.info).darken(0.65).hex();
        }

        if (color === "success") {
            borderColor = Color(colors.success).darken(0.2).hex();
            headerColor = {
                backgroundColor: colors.success,
                color: colors.successText,
                borderColor: Color(colors.success).darken(0.2).hex()
            };
        }

        if (color === "success2") {
            borderColor = Color(colors.success).darken(0.2).hex();
            backgroundColor = Color(colors.success).hex();
            bodyColor = colors.successText;
            headerColor = {
                backgroundColor: colors.success,
                color: colors.successText,
                borderColor: Color(colors.success).darken(0.2).hex()
            };
        }

        if (color === "warning") {
            borderColor = Color(colors.warning).darken(0.2).hex();
            headerColor = {
                backgroundColor: colors.warning,
                color: colors.warningText,
                borderColor: Color(colors.warning).darken(0.2).hex()
            };
            headingBorderColor = colors.warningText;
        }

        if (color === "warning2") {
            borderColor = Color(colors.warning).darken(0.2).hex();
            backgroundColor = colors.warning;
            bodyColor = colors.warningText;
            headerColor = {
                backgroundColor: colors.warning,
                color: colors.warningText,
                borderColor: Color(colors.warning).darken(0.2).hex()
            };
            headingBorderColor = colors.warningText;
        }

        if (color === "danger") {
            borderColor = Color(colors.danger).darken(0.2).hex();
            bodyColor = colors.textColor;
            headerColor = {
                backgroundColor: colors.danger,
                color: colors.dangerText,
                borderColor: Color(colors.danger).darken(0.2).hex()
            };
        }

        if (color === "danger2") {
            borderColor = Color(colors.danger).darken(0.2).hex();
            backgroundColor = Color(colors.danger).lighten(0.5).hex();
            headerColor = {
                backgroundColor: colors.danger,
                color: colors.dangerText,
                borderColor: Color(colors.danger).darken(0.2).hex()
            };
        }

        if (color === "primary") {
            borderColor = Color(colors.primary).darken(0.2).hex();
            headerColor = {
                backgroundColor: colors.primary,
                color: colors.textColor,
                borderColor: Color(colors.primary).darken(0.2).hex()
            };
            headingBorderColor = colors.primaryText;
        }

        if (color === "primary2") {
            borderColor = Color(colors.primary).darken(0.2).hex();
            backgroundColor = colors.primary;
            bodyColor = colors.primaryText;
            headerColor = {
                backgroundColor: colors.primary,
                color: colors.primaryText,
                borderColor: Color(colors.primary).darken(0.2).hex()
            };
            headingBorderColor = colors.primaryText;
        }

        return {
            borderColor,
            backgroundColor,
            headerColor,
            bodyColor,
            headingBorderColor
        }
    }, [color, colors]);

    let iconColor = colorStyle.bodyColor || colors.textColor;
    if (iconColorOverride) {
        iconColor = colors[iconColorOverride];
    }

    return (
        <Card heading={heading} icon={icon} iconSize={iconSize} color={color} >
            <div className={`flex w-full space-x-5`}>
                {!!icon && !heading && <UTIcon color={iconColor} type={icon} size={iconSize} />}
                <div className={"flex-1"}>
                    {children}
                </div>
                {right}
            </div>
        </Card >
    )
}
