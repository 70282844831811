export const PAPER_FIELDS_FRAGMENT = /* GraphQL */ `
  fragment PaperFields on Paper {
    id
    examId
    name
    type
    time
    totalScore
    scoreThresholds {
      green
      amber
    }
    questions {
      id
      paperId
      questionId
      name
      sort
      previousQuestion
    }
    cb
    sb
    co
    so
  }
`;
