import Base from "./base/index";
import ServiceDeskClient from "../client";
import CognitoService from "../../cognito";
import { SendMessageInput, IStartConversationInput } from "./types";
import {
  SEND_MESSAGE,
  START_CONVERSATION,
} from "./base/graphql";

export const sendMessage = async (input: SendMessageInput) => {
  let data: any | undefined;
  try {
    const token = await CognitoService.getToken();

    data = (
      await ServiceDeskClient.request({
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          query: SEND_MESSAGE,
          variables: {
            input,
          },
        },
      })
    )?.data;

    return {
      data: data.data.sendMessage,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};

export const startConversation = async (input: IStartConversationInput) => {
  let data: any | undefined;
  try {
    const token = await CognitoService.getToken();

    data = (
      await ServiceDeskClient.request({
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          query: START_CONVERSATION,
          variables: {
            input,
          },
        },
      })
    )?.data;

    return {
      data: data.data.startConversation,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};

export default {
  ...Base,
  ...{
    sendMessage,
    startConversation,
  },
};
