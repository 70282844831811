export const MAILBOX_FIELDS_FRAGMENT = /* GraphQL */ `
  fragment MailboxFields on Mailbox {
    id
    tenantId
    name
    email
    forwardToEmail
    partnerId
    cb
	  co
  }
`;


export const GET_MAILBOXES = /* GraphQL */ `
  ${MAILBOX_FIELDS_FRAGMENT}
  query GetMailboxes($input: GetMailboxesInput!) {
    getMailboxes(input: $input) {
      data {
        ...MailboxFields
      }
      nextToken
    }
  }
`;

export const GET_MAILBOX = /* GraphQL */ `
  ${MAILBOX_FIELDS_FRAGMENT}
  query GetMailbox($input: GetMailboxInput!) {
    getMailbox(input: $input) {
      ...MailboxFields
    }
  }
`;

export const GET_MAILBOX_BY_PARTNER_ID = /* GraphQL */ `
  ${MAILBOX_FIELDS_FRAGMENT}
  query GetMailboxByPartnerId($input: GetMailboxByPartnerIdInput!) {
    getMailboxByPartnerId(input: $input) {
      data {
        ...MailboxFields
      }
      nextToken
    }
  }
`;

export const UPDATE_MAILBOX = /* GraphQL */ `
  ${MAILBOX_FIELDS_FRAGMENT}
  mutation UpdateMailbox($input: UpdateMailboxInput!) {
      updateMailbox(input: $input) {
        ...MailboxFields
      }
  }
`;

export const CREATE_MAILBOX = /* GraphQL */ `
  ${MAILBOX_FIELDS_FRAGMENT}
  mutation CreateMailboxWithForwardToEmail($input: CreateMailboxWithForwardToEmailInput!) {
    createMailboxWithForwardToEmail(input: $input) {
        ...MailboxFields
      }
  }
`;

export const REMOVE_MAILBOX = /* GraphQL */ `
  ${MAILBOX_FIELDS_FRAGMENT}
  mutation DeleteMailbox($input: DeleteMailboxInput!) {
    deleteMailbox(input: $input) {
      ...MailboxFields
      }
  }
`;

// Previous version of create mailbox that doesn't use the lambda to create the forwardToEmail
export const CREATE_MAILBOX_OLD = /* GraphQL */ `
  ${MAILBOX_FIELDS_FRAGMENT}
  mutation CreateMailbox($input: CreateMailboxInput!) {
      createMailbox(input: $input) {
        ...MailboxFields
      }
  }
`;