export const COURSE_FIELDS_FRAGMENT = /* GraphQL */ `
  fragment CourseFields on Course {
    id
    name
    slug
    hours
    image {
      id
      name
      url
      title
    }
    accreditationImages {
      id
      name
      url
      title
    }
    desc
    instructions
    modules {
      moduleId
      name
      slug
      sort
    }
    exams {
      courseId
      examId
      name
      status
      sort
    }
    glossary {
      id
      courseId
      glossaryId
    }
    modelContents {
      id
      name
      courseId
      modelContentId
    }
    cyclicExtensionSKU
    cb
    sb
    co
    so
  }
`;

export const CREATE_COURSE = /* GraphQL */ `
  ${COURSE_FIELDS_FRAGMENT}
  mutation CreateCourse($input: CreateCourseInput!) {
    createCourse(input: $input) {
      ...CourseFields
    }
  }
`;

export const UPDATE_COURSE = /* GraphQL */ `
  ${COURSE_FIELDS_FRAGMENT}
  mutation UpdateCourse($input: UpdateCourseInput!) {
    updateCourse(input: $input) {
      ...CourseFields
    }
  }
`;
export const DELETE_COURSE = /* GraphQL */ `
  ${COURSE_FIELDS_FRAGMENT}
  mutation DeleteCourse($input: DeleteCourseInput!) {
    deleteCourse(input: $input) {
      ...CourseFields
    }
  }
`;

export const GET_COURSE = /* GraphQL */ `
  ${COURSE_FIELDS_FRAGMENT}
  query GetCourse($input: GetCourseInput!) {
    getCourse(input: $input) {
      ...CourseFields
    }
  }
`;

export const GET_COURSE_WITH = /* GraphQL */ `
  ${COURSE_FIELDS_FRAGMENT}
  query GetCourseWith($input: GetCourseInput!) {
    getCourseWith(input: $input) {
      ...CourseFields
    }
  }
`;

export const GET_BY_COURSE_NAME = /* GraphQL */ `
  ${COURSE_FIELDS_FRAGMENT}
  query GetByCourseName($input: GetByNameCourseInput!) {
    getByCourseName(input: $input) {
      data {
        ...CourseFields
      }
      nextToken
    }
  }
`;
