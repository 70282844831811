import { ServiceResponse } from "../../../types";
import StudentExamPaperClient from "../../client";
import CognitoService from "../../../cognito";
import {
  ICreateStudentExamPaperInput,
  IGetStudentExamPapersByGSI1Input,
  IGetStudentExamPapersByGSI2Input,
  IGetStudentExamPaperInput,
  IUpdateStudentExamPaperInput,
  IDeleteStudentExamPaperInput,
  IGetStudentExamPaperByStudentExamIdInput,
} from "./types";
import {
  CREATE_STUDENT_EXAM_PAPER,
  GET_STUDENT_EXAM_PAPERS_BY_GSI1,
  GET_STUDENT_EXAM_PAPERS_BY_GSI2,
  GET_STUDENT_EXAM_PAPER_WITH,
  GET_STUDENT_EXAM_PAPER,
  UPDATE_STUDENT_EXAM_PAPER,
  DELETE_STUDENT_EXAM_PAPER,
  GET_STUDENT_EXAM_PAPERS_BY_STUDENT_EXAM_ID,
} from "./graphql";
import { IStudentExamPaper } from "./types";
export const createStudentExamPaper = async (
  input: ICreateStudentExamPaperInput
): Promise<ServiceResponse<IStudentExamPaper | undefined>> => {
  let data: any | undefined;
  const token = await CognitoService.getToken();
  if (!token) throw new Error("No token found");
  try {
    data = (
      await StudentExamPaperClient.request({
        method: "post",
        headers: {
          Authorization: `Bearer ${token} `,
        },
        data: {
          query: CREATE_STUDENT_EXAM_PAPER,
          variables: {
            input,
          },
        },
      })
    )?.data;
    return {
      data: data.data.createStudentExamPaper,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};
export const getStudentExamPaper = async (
  input: IGetStudentExamPaperInput
): Promise<ServiceResponse<IStudentExamPaper | undefined>> => {
  let data: any | undefined;
  const token = await CognitoService.getToken();
  if (!token) throw new Error("No token found");
  try {
    data = (
      await StudentExamPaperClient.request({
        method: "post",
        headers: {
          Authorization: `Bearer ${token} `,
        },
        data: {
          query: GET_STUDENT_EXAM_PAPER,
          variables: {
            input,
          },
        },
      })
    )?.data;
    return {
      data: data.data.getStudentExamPaper,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};
export const getStudentExamPapersByGSI1 = async (
  input: IGetStudentExamPapersByGSI1Input
): Promise<ServiceResponse<IStudentExamPaper[] | undefined>> => {
  let data: any | undefined;
  const token = await CognitoService.getToken();
  if (!token) throw new Error("No token found");
  try {
    data = (
      await StudentExamPaperClient.request({
        method: "post",
        headers: {
          Authorization: `Bearer ${token} `,
        },
        data: {
          query: GET_STUDENT_EXAM_PAPERS_BY_GSI1,
          variables: {
            input,
          },
        },
      })
    )?.data;
    return {
      ...data.data.getStudentExamPapersByGSI1,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};
export const getStudentExamPapersByStudentExamId = async (
  input: IGetStudentExamPaperByStudentExamIdInput
): Promise<ServiceResponse<IStudentExamPaper[] | undefined>> => {
  let data: any | undefined;
  const token = await CognitoService.getToken();
  if (!token) throw new Error("No token found");
  try {
    data = (
      await StudentExamPaperClient.request({
        method: "post",
        headers: {
          Authorization: `Bearer ${token} `,
        },
        data: {
          query: GET_STUDENT_EXAM_PAPERS_BY_STUDENT_EXAM_ID,
          variables: {
            input,
          },
        },
      })
    )?.data;
    return {
      ...data.data.getStudentExamPapersByStudentExamId,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};
export const getStudentExamPapersByGSI2 = async (
  input: IGetStudentExamPapersByGSI2Input
): Promise<ServiceResponse<IStudentExamPaper[] | undefined>> => {
  let data: any | undefined;
  const token = await CognitoService.getToken();
  if (!token) throw new Error("No token found");
  try {
    data = (
      await StudentExamPaperClient.request({
        method: "post",
        headers: {
          Authorization: `Bearer ${token} `,
        },
        data: {
          query: GET_STUDENT_EXAM_PAPERS_BY_GSI2,
          variables: {
            input,
          },
        },
      })
    )?.data;
    return {
      ...data.data.getStudentExamPapersByGSI2,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};
export const getStudentExamPaperWith = async (
  input: IGetStudentExamPaperInput
): Promise<ServiceResponse<IStudentExamPaper | undefined>> => {
  let data: any | undefined;
  const token = await CognitoService.getToken();
  if (!token) throw new Error("No token found");
  try {
    data = (
      await StudentExamPaperClient.request({
        method: "post",
        headers: {
          Authorization: `Bearer ${token} `,
        },
        data: {
          query: GET_STUDENT_EXAM_PAPER_WITH,
          variables: {
            input,
          },
        },
      })
    )?.data;
    return {
      data: data.data.getStudentExamPaperWith,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};
export const updateStudentExamPaper = async (
  input: IUpdateStudentExamPaperInput
): Promise<ServiceResponse<IStudentExamPaper | undefined>> => {
  let data: any | undefined;
  const token = await CognitoService.getToken();
  if (!token) throw new Error("No token found");
  try {
    data = (
      await StudentExamPaperClient.request({
        method: "post",
        headers: {
          Authorization: `Bearer ${token} `,
        },
        data: {
          query: UPDATE_STUDENT_EXAM_PAPER,
          variables: {
            input,
          },
        },
      })
    )?.data;
    return {
      data: data.data.updateStudentExamPaper,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};
export const deleteStudentExamPaper = async (
  input: IDeleteStudentExamPaperInput
): Promise<ServiceResponse<IStudentExamPaper | undefined>> => {
  let data: any | undefined;
  const token = await CognitoService.getToken();
  if (!token) throw new Error("No token found");
  try {
    data = (
      await StudentExamPaperClient.request({
        method: "post",
        headers: {
          Authorization: `Bearer ${token} `,
        },
        data: {
          query: DELETE_STUDENT_EXAM_PAPER,
          variables: {
            input,
          },
        },
      })
    )?.data;
    return {
      data: data.data.deleteStudentExamPaper,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};
const PlatformService = {
  createStudentExamPaper,
  getStudentExamPapersByGSI2,
  getStudentExamPaperWith,
  getStudentExamPaper,
  updateStudentExamPaper,
  deleteStudentExamPaper,
  getStudentExamPapersByStudentExamId,
};
export default {
  ...PlatformService,
};
