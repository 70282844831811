import { TENANT_ID } from "../../config-global";
import { FileManagerService } from "../../services/file-manager";
import { PresignedData as IPresignedData } from "../../services/file-manager/file/types";

export const uploadImagesAndReplaceInMessage = async (message: string, mailboxId: string) => {
  const images = findImages(message);
  // get presigned data for each image
  const presignedData = await Promise.all(
    images.map(async (image) => {
      const presignedDataForImage = await FileManagerService.File.createPresignedData(
        image.data,
        TENANT_ID,
        `ServiceDesk/${mailboxId}`
      );

      if (!presignedDataForImage?.success || !presignedDataForImage?.data) return false;

      return {
        data: presignedDataForImage.data,
        image,
      };
    })
  );

  // if ANY failed return
  if (presignedData.some((x) => !x)) return false;

  const items = presignedData.filter((x) => x) as {
    data: IPresignedData;
    image: { index: number; name: string; type: string; data: File };
  }[];

  // upload each image to File Manager
  const response = await Promise.all(
    items.map(async (item, i) => {
      const response = FileManagerService.File.uploadFile(
        item.data,
        item.image.data
      );

      if (!response) return false;

      return {
        index: item.image.index,
        url: item.data.objectUrl,
      };
    })
  );

  // if ANY failed return
  if (response.some((x) => !x)) return false;

  const files = response as { index: number; url: string }[];

  // replace the images in the message with the urls from the File Manager upload
  let newMessage = message;
  files.forEach((file) => {
    const URL = file.url;
    newMessage = newMessage.replace(images[file.index].blobStr, URL);
  });

  return newMessage;
};
const findImages = (message: string) => {
  const regex = /<img.*?src="(.*?)"/g;
  const images: {
    index: number;
    name: string;
    type: string;
    data: File;
    blobStr: string;
  }[] = [];

  let match;
  let i = 0;
  while ((match = regex.exec(message))) {
    const blobStr: string = match[1] as any;
    const filename = `attachment-${i}${1}`;
    const data = base64ToFile(blobStr, filename);
    images.push({
      index: i,
      data,
      name: filename,
      type: data.type,
      blobStr,
    });
    i++;
  }
  return images;
};
function base64ToFile(base64String: string, filename: string) {
  // Split the base64 string to get the content type and the actual base64 data
  const arr = base64String.split(",");
  const mimeType = arr?.[0].match(/:(.*?);/)?.[1];
  const ext = mimeType?.split("/")[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  // Create a Blob object from the Uint8Array
  const blob = new Blob([u8arr], { type: mimeType });

  // Convert the Blob to a File
  return new File([blob], `${filename}.${ext}`, { type: mimeType });
}
