export const MESSAGE_FIELDS_FRAGMENT = /* GraphQL */ `
  fragment MessageFields on Message {
    id
    conversationId
    mailboxId
    tenantId
    contactId
    contact {
      id
      name
      email
      firstName
      lastName
    }
    content
    subject
  }
`;

export const GET_MESSAGE = /* GraphQL */ `
  ${MESSAGE_FIELDS_FRAGMENT}
  query GetMessage($input: GetMessageInput!) {
    getMessage(input: $input) {
      ...MessageFields
    }
  }
`;

export const CREATE_MESSAGE = /* GraphQL */ `
  ${MESSAGE_FIELDS_FRAGMENT}
  mutation CreateMessage($input: CreateMessageInput!) {
    createMessage(input: $input) {
      ...MessageFields
    }
  }
`;

export const SEND_MESSAGE = /* GraphQL */ `
  ${MESSAGE_FIELDS_FRAGMENT}
  mutation SendMessage($input: SendMessageInput!) {
    sendMessage(input: $input) {
      ...MessageFields
    }
  }
`;

export const START_CONVERSATION = /* GraphQL */ `
  ${MESSAGE_FIELDS_FRAGMENT}
  mutation StartConversation($input: StartConversationInput!) {
    startConversation(input: $input) {
      ...MessageFields
    }
  }
`;

export const GET_MESSAGES = /* GraphQL */ `
  ${MESSAGE_FIELDS_FRAGMENT}
  query GetMessages($input: GetMessagesInput!) {
    getMessages(input: $input) {
      data {
        ...MessageFields
      }
      nextToken
    }
  }
`;
