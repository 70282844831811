import { ServiceResponse } from '../../../types';

import StudentClient from '../../client';
import CognitoService from '../../../cognito';
import { IStudent, IGetStudentInput, IGetStudentsInput, ICreateStudentInput, IUpdateStudentInput, IDeleteStudentInput } from './types';
import { GET_STUDENT, GET_STUDENT_WITH, GET_STUDENTS, CREATE_STUDENT, UPDATE_STUDENT, DELETE_STUDENT, CREATE_STUDENT_WITH_COGNITO, UPDATE_STUDENT_WITH_COGNITO } from './graphql';

export const createStudent = async (
    input: ICreateStudentInput
): Promise<ServiceResponse<IStudent | undefined>> => {
    let data: any | undefined;

    const token = await CognitoService.getToken();
    if (!token) throw new Error('No token found');

    try {
        data = (
            await StudentClient.request({
                method: 'post',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: {
                    query: CREATE_STUDENT,
                    variables: {
                        input
                    },
                },
            })
        )?.data;

        return {
            data: data.data.createStudent,
            success: true,
        }
    } catch (err) {
        return {
            success: false,
        };
    }
};

export const createStudentWithCognito = async (
    input: ICreateStudentInput
): Promise<ServiceResponse<IStudent | undefined>> => {
    let data: any | undefined;

    const token = await CognitoService.getToken();
    if (!token) throw new Error('No token found');

    try {
        data = (
            await StudentClient.request({
                method: 'post',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: {
                    query: CREATE_STUDENT_WITH_COGNITO,
                    variables: {
                        input
                    },
                },
            })
        )?.data;

        return {
            data: data.data.createStudentWithCognito,
            success: true,
        };
    } catch (err) {
        return {
            success: false,
        };
    }
};

export const getStudent = async (
    input: IGetStudentInput
): Promise<ServiceResponse<IStudent | undefined>> => {
    let data: any | undefined;

    const token = await CognitoService.getToken();
    if (!token) throw new Error('No token found');

    try {
        data = (
            await StudentClient.request({
                method: 'post',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: {
                    query: GET_STUDENT,
                    variables: {
                        input
                    },
                },
            })
        )?.data;

        return {
            data: data.data.getStudent,
            success: true,
        };
    } catch (err) {
        return {
            success: false,
        };
    }
};

export const getStudentWith = async (
    input: IGetStudentInput
): Promise<ServiceResponse<IStudent | undefined>> => {
    let data: any | undefined;

    const token = await CognitoService.getToken();
    if (!token) throw new Error('No token found');

    try {
        data = (
            await StudentClient.request({
                method: 'post',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: {
                    query: GET_STUDENT_WITH,
                    variables: {
                        input
                    },
                },
            })
        )?.data;

        return {
            data: data.data.getStudentWith,
            success: true,
        };
    } catch (err) {
        return {
            success: false,
        };
    }
};

export const updateStudent = async (
    input: IUpdateStudentInput
): Promise<ServiceResponse<IStudent | undefined>> => {
    let data: any | undefined;

    const token = await CognitoService.getToken();
    if (!token) throw new Error('No token found');

    try {
        data = (
            await StudentClient.request({
                method: 'post',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: {
                    query: UPDATE_STUDENT,
                    variables: {
                        input
                    },
                },
            })
        )?.data;

        return {
            data: data.data.updateStudent,
            success: true,
        };
    } catch (err) {
        return {
            success: false,
        };
    }
};


export const updateStudentWithCognito = async (
    input: IUpdateStudentInput
): Promise<ServiceResponse<IStudent | undefined>> => {
    let data: any | undefined;

    const token = await CognitoService.getToken();
    if (!token) throw new Error('No token found');

    try {
        data = (
            await StudentClient.request({
                method: 'post',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: {
                    query: UPDATE_STUDENT_WITH_COGNITO,
                    variables: {
                        input
                    },
                },
            })
        )?.data;

        return {
            ...data,
            data: data.data.updateStudentWithCognito,
            success: !!data.errors || !!data.data.updateStudentWithCognito,
        };


    } catch (err) {
        console.error("updateStudentWithCognito", err);
        return {
            success: false,
        };
    }
};

export const deleteStudent = async (
    input: IDeleteStudentInput
): Promise<ServiceResponse<IStudent | undefined>> => {
    let data: any | undefined;

    const token = await CognitoService.getToken();
    if (!token) throw new Error('No token found');

    try {
        data = (
            await StudentClient.request({
                method: 'post',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: {
                    query: DELETE_STUDENT,
                    variables: {
                        input
                    },
                },
            })
        )?.data;

        return {
            data: data.data.deleteStudent,
            success: true,
        };
    } catch (err) {
        return {
            success: false,
        };
    }
};

export const getStudents = async (
    input: IGetStudentsInput
): Promise<ServiceResponse<IStudent[] | undefined>> => {
    let data: any | undefined;

    const token = await CognitoService.getToken();
    if (!token) throw new Error('No token found');

    try {
        data = (
            await StudentClient.request({
                method: 'post',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: {
                    query: GET_STUDENTS,
                    variables: {
                        input
                    },
                },
            })
        )?.data;

        return {
            ...data.data.getStudents,
            success: true,
        };
    } catch (err) {
        return {
            success: false,
        };
    }
};

const StudentService = {
    createStudent,
    createStudentWithCognito,
    getStudents,
    getStudent,
    getStudentWith,
    updateStudent,
    updateStudentWithCognito,
    deleteStudent,
};

export default {
    ...StudentService,
};
