import { MODULE_FIELDS_FRAGMENT } from "./base/graphql";

export const GET_BUILD_MODULES = /* GraphQL */ `
  ${MODULE_FIELDS_FRAGMENT}
  query getBuildModules($input: GetBuildModulesInput!) {
    getBuildModules(input: $input) {
      data {
        ...ModuleFields
      }
      nextToken
    }
  }
`;
export const GET_BUILD_MODULE = /* GraphQL */ `
  ${MODULE_FIELDS_FRAGMENT}
  query getBuildModule($input: GetBuildModuleInput!) {
    getBuildModule(input: $input) {
      ...ModuleFields
    }
  }
`;
