import { MESSAGE_FIELDS_FRAGMENT } from './base/graphql';

export const NEW_MESSAGE_FOR_STUDENT_SUB = /* GraphQL */ `
  ${MESSAGE_FIELDS_FRAGMENT}
  subscription onNewMessageForStudent($tenantId: String!, $contactId: String!) {
    onNewMessageForStudent(tenantId: $tenantId, contactId: $contactId) {
      ...MessageFields
    }
  }
`;
