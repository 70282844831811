import React, { lazy } from 'react';
import 'react-quill/dist/quill.snow.css';
import { ReactQuillProps } from 'react-quill';

import Toolbar, { formats } from './toolbar';

const ReactQuill = lazy(() => import('react-quill'));

export interface EditorProps extends ReactQuillProps {
    error?: boolean;
    simple?: boolean;
    helperText?: React.ReactNode;
    style?: React.CSSProperties;
}

export default function Editor({
    id = 'minimal-quill',
    error,
    simple = true,
    helperText,
    style,
    ...other
}: EditorProps) {

    const modules = {
        toolbar: {
            container: `#${id}`,
        },
        history: {
            delay: 500,
            maxStack: 100,
            userOnly: true,
        },
        clipboard: {
            matchVisual: false,
        },
    };

    return (
        <div id={`${id}-wrapper`} style={style || {}} className={"StyledEditor"}>
            <Toolbar id={id} simple={simple} />
            <ReactQuill
                modules={modules}
                formats={formats}
                {...other}
            />
            {helperText && helperText}
        </div>
    );
}