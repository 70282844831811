import { QUESTION_FIELDS_FRAGMENT } from "./base/graphql";

export const GET_BUILD_QUESTIONS = /* GraphQL */ `
  ${QUESTION_FIELDS_FRAGMENT}
  query getBuildQuestions($input: GetBuildQuestionsInput!) {
    getBuildQuestions(input: $input) {
      data {
        ...QuestionFields
      }
      nextToken
    }
  }
`;
export const GET_BUILD_QUESTION = /* GraphQL */ `
  ${QUESTION_FIELDS_FRAGMENT}
  query getBuildQuestion($input: GetBuildQuestionInput!) {
    getBuildQuestion(input: $input) {
      ...QuestionFields
    }
  }
`;
