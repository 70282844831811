import FileManagerClient from '../../client';
import CognitoService from '../../../cognito';
import { ServiceResponse } from '../../../types';
import {
  GET_FILE,
  CREATE_FILE,
  DELETE_FILE,
  UPDATE_FILE,
  GET_FILE_WITH,
  GET_BY_FILE_NAME,
  GET_BY_FILE_TITLE,
} from './graphql';
import {
  IFile,
  GetFileInput,
  CreateFileInput,
  UpdateFileInput,
  DeleteFileInput,
  GetByFileNameInput,
  GetByFileTitleInput,
} from './types';

export const getFile = async (input: GetFileInput): Promise<ServiceResponse<IFile>> => {
  let data: any | undefined;
  try {
    const token = await CognitoService.getToken();

    data = (
      await FileManagerClient.request({
        method: 'post',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          query: GET_FILE,
          variables: {
            input,
          },
        },
      })
    )?.data;

    return {
      data: data.data.getFile,
      success: true,
    } as ServiceResponse<IFile>;
  } catch (err) {
    console.error('getFile', err);
    return {
      success: false,
    } as ServiceResponse<IFile>;
  }
};
export const getFileWith = async (input: GetFileInput): Promise<ServiceResponse<IFile>> => {
  let data: any | undefined;
  try {
    const token = await CognitoService.getToken();

    data = (
      await FileManagerClient.request({
        method: 'post',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          query: GET_FILE_WITH,
          variables: {
            input,
          },
        },
      })
    )?.data;

    return {
      data: data.data.getFileWith,
      success: true,
    } as ServiceResponse<IFile>;
  } catch (err) {
    console.error('getFile', err);
    return {
      success: false,
    } as ServiceResponse<IFile>;
  }
};

export const createFile = async (input: CreateFileInput): Promise<ServiceResponse<IFile>> => {
  let data: any | undefined;
  try {
    const token = await CognitoService.getToken();

    data = (
      await FileManagerClient.request({
        method: 'post',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          query: CREATE_FILE,
          variables: {
            input,
          },
        },
      })
    )?.data;

    return {
      data: data.data.createFile,
      success: true,
    } as ServiceResponse<IFile>;
  } catch (err) {
    return {
      success: false,
    } as ServiceResponse<IFile>;
  }
};

export const updateFile = async (input: UpdateFileInput): Promise<ServiceResponse<IFile>> => {
  let data: any | undefined;
  try {
    const token = await CognitoService.getToken();

    data = (
      await FileManagerClient.request({
        method: 'post',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          query: UPDATE_FILE,
          variables: {
            input,
          },
        },
      })
    )?.data;

    return {
      data: data.data.updateFile,
      success: true,
    } as ServiceResponse<IFile>;
  } catch (err) {
    return {
      success: false,
    } as ServiceResponse<IFile>;
  }
};

export const deleteFile = async (input: DeleteFileInput): Promise<ServiceResponse<IFile>> => {
  let data: any | undefined;
  try {
    const token = await CognitoService.getToken();

    data = (
      await FileManagerClient.request({
        method: 'post',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          query: DELETE_FILE,
          variables: {
            input,
          },
        },
      })
    )?.data;

    return {
      data: data.data.deleteFile,
      success: true,
    } as ServiceResponse<IFile>;
  } catch (err) {
    return {
      success: false,
    } as ServiceResponse<IFile>;
  }
};

export const getFilesByName = async (
  input: GetByFileNameInput,
  nextToken?: string
): Promise<ServiceResponse<IFile[]>> => {
  let data: any | undefined;
  try {
    const token = await CognitoService.getToken();

    data = (
      await FileManagerClient.request({
        method: 'post',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          query: GET_BY_FILE_NAME,
          variables: {
            input: {
              ...input,
              nextToken,
            },
          },
        },
      })
    )?.data;

    return {
      ...data.data.getByFileName,
      success: true,
    } as ServiceResponse<IFile[]>;
  } catch (err) {
    return {
      success: false,
    } as ServiceResponse<IFile[]>;
  }
};
export const getFilesByTitle = async (
  input: GetByFileTitleInput,
  nextToken?: string
): Promise<ServiceResponse<IFile[]>> => {
  let data: any | undefined;
  try {
    const token = await CognitoService.getToken();

    data = (
      await FileManagerClient.request({
        method: 'post',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          query: GET_BY_FILE_TITLE,
          variables: {
            input: {
              ...input,
              nextToken,
            },
          },
        },
      })
    )?.data;

    return {
      ...data.data.getByFileTitle,
      success: true,
    } as ServiceResponse<IFile[]>;
  } catch (err) {
    return {
      success: false,
    } as ServiceResponse<IFile[]>;
  }
};

export default {
  getFile,
  getFileWith,
  createFile,
  updateFile,
  deleteFile,
  getFilesByName,
  getFilesByTitle,
};
