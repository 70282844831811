import { ServiceResponse } from '../../../types';

import QuestionTypeClient from '../../client';
import CognitoService from '../../../cognito';
import { GET_QUESTION_TYPE, GET_QUESTION_TYPES_BY_GSI1, CREATE_QUESTION_TYPE, UPDATE_QUESTION_TYPE, DELETE_QUESTION_TYPE, GET_QUESTION_TYPE_WITH } from './graphql';
import { IQuestionType, IGetQuestionTypeInput, IGetQuestionTypesInput, ICreateQuestionTypeInput, IUpdateQuestionTypeInput, IDeleteQuestionTypeInput } from './types';

export const createQuestionType = async (
  input: ICreateQuestionTypeInput
): Promise<ServiceResponse<IQuestionType | undefined>> => {
  let data: any | undefined;
  const token = await CognitoService.getToken();
  if (!token) throw new Error('No token found');
  try {
    data = (
      await QuestionTypeClient.request({
        method: 'post',
        headers: {
          Authorization: `Bearer ${token} `,
        },
        data: {
          query: CREATE_QUESTION_TYPE,
          variables: {
            input
          },
        },
      })
    )?.data;
    return {
      data: data.data.createQuestionType,
      success: true,
    }
  } catch (err) {
    return {
      success: false,
    };
  }
};
export const getQuestionType = async (
  input: IGetQuestionTypeInput
): Promise<ServiceResponse<IQuestionType | undefined>> => {
  let data: any | undefined;
  const token = await CognitoService.getToken();
  if (!token) throw new Error('No token found');
  try {
    data = (
      await QuestionTypeClient.request({
        method: 'post',
        headers: {
          Authorization: `Bearer ${token} `,
        },
        data: {
          query: GET_QUESTION_TYPE,
          variables: {
            input
          },
        },
      })
    )?.data;
    return {
      data: data.data.getQuestionType,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};
export const getQuestionTypesByGSI1 = async (
  input: IGetQuestionTypesInput
): Promise<ServiceResponse<IQuestionType[] | undefined>> => {
  let data: any | undefined;
  const token = await CognitoService.getToken();
  if (!token) throw new Error('No token found');
  try {
    data = (
      await QuestionTypeClient.request({
        method: 'post',
        headers: {
          Authorization: `Bearer ${token} `,
        },
        data: {
          query: GET_QUESTION_TYPES_BY_GSI1,
          variables: {
            input
          },
        },
      })
    )?.data;
    return {
      ...data.data.getQuestionTypesByGSI1,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};
export const getQuestionTypeWith = async (
  input: IGetQuestionTypeInput
): Promise<ServiceResponse<IQuestionType[] | undefined>> => {
  let data: any | undefined;
  const token = await CognitoService.getToken();
  if (!token) throw new Error('No token found');
  try {
    data = (
      await QuestionTypeClient.request({
        method: 'post',
        headers: {
          Authorization: `Bearer ${token} `,
        },
        data: {
          query: GET_QUESTION_TYPE_WITH,
          variables: {
            input
          },
        },
      })
    )?.data;
    return {
      data: data.data.getQuestionTypeWith,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};
export const updateQuestionType = async (
  input: IUpdateQuestionTypeInput
): Promise<ServiceResponse<IQuestionType | undefined>> => {
  let data: any | undefined;
  const token = await CognitoService.getToken();
  if (!token) throw new Error('No token found');
  try {
    data = (
      await QuestionTypeClient.request({
        method: 'post',
        headers: {
          Authorization: `Bearer ${token} `,
        },
        data: {
          query: UPDATE_QUESTION_TYPE,
          variables: {
            input
          },
        },
      })
    )?.data;
    return {
      data: data.data.updateQuestionType,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};
export const deleteQuestionType = async (
  input: IDeleteQuestionTypeInput
): Promise<ServiceResponse<IQuestionType | undefined>> => {
  let data: any | undefined;
  const token = await CognitoService.getToken();
  if (!token) throw new Error('No token found');
  try {
    data = (
      await QuestionTypeClient.request({
        method: 'post',
        headers: {
          Authorization: `Bearer ${token} `,
        },
        data: {
          query: DELETE_QUESTION_TYPE,
          variables: {
            input
          },
        },
      })
    )?.data;
    return {
      data: data.data.deleteQuestionType,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};
const PlatformService = {
  createQuestionType,
  getQuestionTypesByGSI1,
  getQuestionTypeWith,
  getQuestionType,
  updateQuestionType,
  deleteQuestionType,
};
export default {
  ...PlatformService,
};