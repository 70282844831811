import axios from "axios";

import { FILE_MANAGER_API } from "../../config-global";

const FileManagerClient = axios.create({ baseURL: FILE_MANAGER_API });

FileManagerClient.interceptors.response.use(
  (res) => res,
  (error) => {
    console.error("FileManagerClient", "interceptor", error);
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    );
  }
);

export default FileManagerClient;
