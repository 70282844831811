export const TOPIC_FIELDS_FRAGMENT = /* GraphQL */ `
  fragment TopicFields on Topic {
    id
    tenantId
    name
    slug
    template
    instructions
    content
    tips
    learnt
    video
    assets {
      assetId
      name
      sort
    }
    subTopics {
      subTopicId
      name
      sort
    }
    cb
    sb
    co
    so
  }
`;

export const CREATE_TOPIC = /* GraphQL */ `
  ${TOPIC_FIELDS_FRAGMENT}
  mutation CreateTopic($input: CreateTopicInput!) {
    createTopic(input: $input) {
      ...TopicFields
    }
  }
`;

export const UPDATE_TOPIC = /* GraphQL */ `
  ${TOPIC_FIELDS_FRAGMENT}
  mutation UpdateTopic($input: UpdateTopicInput!) {
    updateTopic(input: $input) {
      ...TopicFields
    }
  }
`;
export const DELETE_TOPIC = /* GraphQL */ `
  ${TOPIC_FIELDS_FRAGMENT}
  mutation DeleteTopic($input: DeleteTopicInput!) {
    deleteTopic(input: $input) {
      ...TopicFields
    }
  }
`;

export const GET_TOPIC = /* GraphQL */ `
  ${TOPIC_FIELDS_FRAGMENT}
  query GetTopic($input: GetTopicInput!) {
    getTopic(input: $input) {
      ...TopicFields
    }
  }
`;

export const GET_TOPIC_WITH = /* GraphQL */ `
  ${TOPIC_FIELDS_FRAGMENT}
  query GetTopicWith($input: GetTopicInput!) {
    getTopicWith(input: $input) {
      ...TopicFields
    }
  }
`;

export const GET_BY_TOPIC_NAME = /* GraphQL */ `
  ${TOPIC_FIELDS_FRAGMENT}
  query GetByTopicName($input: GetByNameTopicInput!) {
    getByTopicName(input: $input) {
      data {
        ...TopicFields
      }
      nextToken
    }
  }
`;
