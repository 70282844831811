import Color from 'color';
import React, { useEffect } from "react";

import { usePartnerContext } from "../../context/partner";

type Props = {
    startedOn: string;
    time: string | number;
    timeoutExpired?: () => void;
    display?: {
        type: "text" | "bar",
        options?: {
            height?: number,
            hoverPauses?: boolean
        }
    };
    paused?: boolean;
};

export default function Countdown({
    startedOn,
    time,
    timeoutExpired = () => { },
    display = { type: "text" },
    paused = false
}: Props) {
    const { colors } = usePartnerContext();
    const [timeLeftWithoutPause, setTimeLeftWithoutPause] = React.useState<number>();
    const [timeLeft, setTimeLeft] = React.useState<number>();
    const [pausedTime, setPausedTime] = React.useState<number>(0);

    useEffect(() => {

        const getNewTimeLeft = (so: string, t: string | number, timeNow: number) => {
            const timeInMiliseconds = +t * 60000;
            const newTimeLeft = new Date(so).getTime() + (timeInMiliseconds) - timeNow;
            return newTimeLeft
        }

        setTimeLeftWithoutPause(getNewTimeLeft(startedOn, time, new Date().getTime()));
        const interval = display.type === "text" ? 1000 : 10;
        const countdown = setInterval(() => {
            setTimeLeftWithoutPause(getNewTimeLeft(startedOn, time, new Date().getTime()));
        }, interval)
        return () => {
            clearInterval(countdown);
        }

    }, [startedOn])

    // HH: It's a little dirty to set the paused time based on the timeLeftWithoutPause being updated, but it's close enough to the correct behavior
    useEffect(() => {
        if (paused) {
            setPausedTime(pausedTime + (display.type === "text" ? 1000 : 10));
        }
        setTimeLeft((timeLeftWithoutPause) + pausedTime);
    }, [timeLeftWithoutPause])

    useEffect(() => {
        // only fire timeout once
        if (timeLeft && timeLeft <= 0) {
            timeoutExpired();
        }
    }, [timeLeft])

    const { hours, minutes, seconds } = millisecondsToHoursMinutesSeconds(timeLeft);
    let color = colors.gray2;

    // HH: If half the time has gone by, change the color to warning
    if (((hours * 60) + minutes) < +time / 2) {
        color = colors.warning;
    }

    // HH: If less than 10 minutes left, change the color to danger
    if (hours === 0 && minutes < 10) {
        color = colors.danger;
    }

    let readableTimeLeft = `${hours} Hours ${minutes} Minutes ${seconds} Seconds`;
    if (readableTimeLeft.includes("NaN")) {
        return null;
    }
    if (timeLeft <= 0) {
        readableTimeLeft = "Time's up!";
        color = colors.success;
    }

    if (display.type === "text") {
        return <div className={"inline-flex px-4 py-2 border-solid border font-bold"} style={{ borderColor: Color(color).darken(0.2).hex(), backgroundColor: color, color: Color(color).isDark() ? "white" : undefined }}><span>{readableTimeLeft}</span></div>;
    } if (display.type === "bar") {
        return <div className={`relative w-full h-${display?.options?.height} rounded-full`}>
            <div className={"relative font-medium text-blue-100 text-center p-0.5 leading-none rounded-full h-full"} style={{
                backgroundColor: colors.gray4,
                width: `${100 - ((timeLeft / (+time * 60000)) * 100)}%`,
            }} />
        </div>
    }

}

const millisecondsToHoursMinutesSeconds = (timeLeft: number) => {
    const seconds = Math.floor((timeLeft / 1000) % 60);
    const minutes = Math.floor((timeLeft / (1000 * 60)) % 60);
    const hours = Math.floor((timeLeft / (1000 * 60 * 60)) % 24);
    return { hours, minutes, seconds };
}
