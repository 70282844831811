import { ASSET_FIELDS_FRAGMENT } from "./base/graphql";

export const GET_BUILD_ASSETS = /* GraphQL */ `
  ${ASSET_FIELDS_FRAGMENT}
  query getBuildAssets($input: GetBuildAssetsInput!) {
    getBuildAssets(input: $input) {
      data {
        ...AssetFields
      }
      nextToken
    }
  }
`;
export const GET_BUILD_ASSET = /* GraphQL */ `
  ${ASSET_FIELDS_FRAGMENT}
  query getBuildAsset($input: GetBuildAssetInput!) {
    getBuildAsset(input: $input) {
      ...AssetFields
    }
  }
`;
