export const QUESTION_TYPE_FIELDS_FRAGMENT = /* GraphQL */ `
  fragment QuestionTypeFields on QuestionType {
    id
    code
    name
    structure {
      type
      label
      optionsIndex
      scrambleOrder
      rowWidth
      enforcedOptionCount
      enforcedOptions {
        label
        value
      }
      generateOptions {
        type
        from
        to
        format
      }
    }
    display
    defaultValue
  }
`;
export const CREATE_QUESTION_TYPE = /* GraphQL */ `
  ${QUESTION_TYPE_FIELDS_FRAGMENT}
  mutation CreateQuestionType($input: CreateQuestionTypeInput!) {
    createQuestionType(input: $input) {
      ...QuestionTypeFields
    }
  }
`;
export const GET_QUESTION_TYPE = /* GraphQL */ `
  ${QUESTION_TYPE_FIELDS_FRAGMENT}
  query GetQuestionType($input: GetQuestionTypeInput!) {
    getQuestionType(input: $input) {
      ...QuestionTypeFields
    }
  }
`;
export const GET_QUESTION_TYPES_BY_GSI1 = /* GraphQL */ `
  ${QUESTION_TYPE_FIELDS_FRAGMENT}
  query GetQuestionTypesByGSI1($input: GetQuestionTypesInput!) {
    getQuestionTypesByGSI1(input: $input) {
      data {
        ...QuestionTypeFields
      }
      nextToken
    }
  }
`;
export const GET_QUESTION_TYPE_WITH = /* GraphQL */ `
  ${QUESTION_TYPE_FIELDS_FRAGMENT}
  query GetQuestionTypeWith($input: GetQuestionTypeWithInput!) {
    getQuestionTypeWith(input: $input) {
      data {
        ...QuestionTypeFields
      }
      nextToken
    }
  }
`;
export const UPDATE_QUESTION_TYPE = /* GraphQL */ `
  ${QUESTION_TYPE_FIELDS_FRAGMENT}
  mutation UpdateQuestionType($input: UpdateQuestionTypeInput!) {
    updateQuestionType(input: $input) {
      ...QuestionTypeFields
    }
  }
`;
export const DELETE_QUESTION_TYPE = /* GraphQL */ `
  ${QUESTION_TYPE_FIELDS_FRAGMENT}
  mutation DeleteQuestionType($input: DeleteQuestionTypeInput!) {
    deleteQuestionType(input: $input) {
      ...QuestionTypeFields
    }
  }
`;
