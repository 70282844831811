import React, { useMemo } from 'react'

import { usePartnerContext } from '../../context/partner'

import { navigate } from 'gatsby'
import { useCourseContext } from '../../context/course';
import { useAuthContext } from '../../context/auth';
import UTIcon from '../../components/icons';
import Button from '../../components/button';
import Color from 'color';

type Props = {
    setShowGlossary: (show: boolean) => void;
    onClose: () => void;
}

export default function MobileMenu({ setShowGlossary, onClose }: Props) {

    const { course } = useCourseContext();
    const { colors, partner } = usePartnerContext();
    const { working, signOut } = useAuthContext();

    const headerLogo = useMemo(() => {
        if (partner?.logo?.url) {
            return <img src={partner.logo.url} alt={partner?.name} className='h-[55px] py-[10px] object-contain' />
        }
        return <h1 className='text-lg font-bold'>
            {partner?.name}
        </h1>
    }, [partner]);

    return (
        <div className='
            fixed top-0 right-0 w-full h-full z-50 
            bg-white'
            style={{
                backgroundColor: colors.gray1
            }}
        >

            {/* Header */}
            <header className='p-[10px] px-5'>
                <div className='flex justify-between items-center'>
                    <div>
                        {headerLogo}
                    </div>
                    <div>
                        <button onClick={onClose}>
                            <UTIcon type='close' size={24} />
                        </button>
                    </div>
                </div>
            </header>

            <nav className='flex flex-col pt-5 pb-10 space-y-2'
                style={{
                    color: colors.textColor
                }}
            >
                {course && <button className='flex items-center py-2.5 px-6 text-left text-lg border-b justify-between' onClick={() => { setShowGlossary(true) }}>
                    Glossary
                    <UTIcon
                        type="caretDoubleRight"
                        className='ml-2'
                        size={18}
                    />
                </button>}
                <button className='flex items-center py-2.5 px-6 text-left text-lg border-b justify-between' onClick={() => { navigate("/"); onClose(); }} disabled={working}>
                    Classroom
                    <UTIcon
                        type="caretDoubleRight"
                        className='ml-2'
                        size={18}
                    />
                </button>
                <button className='flex items-center py-2.5 px-6 text-left text-lg border-b justify-between' onClick={() => { navigate("/my-messages"); onClose(); }} disabled={working}>
                    Messages
                    <UTIcon
                        type="caretDoubleRight"
                        className='ml-2'
                        size={18}
                    />
                </button>
                <button className='flex items-center py-2.5 px-6 text-left text-lg border-b justify-between' onClick={() => { navigate("/my-account"); onClose(); }} disabled={working}>
                    Profile
                    <UTIcon
                        type="caretDoubleRight"
                        className='ml-2'
                        size={18}
                    />
                </button>
                <button className='flex items-center py-2.5 px-6 text-left text-lg border-b justify-between' onClick={() => { navigate("/help/user-guide"); onClose(); }} disabled={working}>
                    Help
                    <UTIcon
                        type="caretDoubleRight"
                        className='ml-2'
                        size={18}
                    />
                </button>
            </nav>

            <div className="flex-1 flex justify-center">
                <Button onClick={signOut} disabled={working}>
                    Sign Out
                </Button>
            </div>

        </div>
    )
}
