import { ServiceResponse } from 'src/services/types';

import WordClient from '../../client';
import CognitoService from '../../../cognito';
import { GET_WORD, GET_WORDS, CREATE_WORD, UPDATE_WORD, DELETE_WORD, GET_WORD_WITH } from './graphql';
import { IWord, IGetWordInput, IGetWordsInput, ICreateWordInput, IUpdateWordInput, IDeleteWordInput } from './types';

export const createWord = async (
  input: ICreateWordInput
): Promise<ServiceResponse<IWord | undefined>> => {
  let data: any | undefined;
  const token = await CognitoService.getToken();
  if (!token) throw new Error('No token found');
  try {
    data = (
      await WordClient.request({
        method: 'post',
        headers: {
          Authorization: `Bearer ${token} `,
        },
        data: {
          query: CREATE_WORD,
          variables: {
            input
          },
        },
      })
    )?.data;
    return {
      data: data.data.createWord,
      success: true,
    }
  } catch (err) {
    return {
      success: false,
    };
  }
};
export const getWord = async (
  input: IGetWordInput
): Promise<ServiceResponse<IWord | undefined>> => {
  let data: any | undefined;
  const token = await CognitoService.getToken();
  if (!token) throw new Error('No token found');
  try {
    data = (
      await WordClient.request({
        method: 'post',
        headers: {
          Authorization: `Bearer ${token} `,
        },
        data: {
          query: GET_WORD,
          variables: {
            input
          },
        },
      })
    )?.data;
    return {
      data: data.data.getWord,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};
export const getWords = async (
  input: IGetWordsInput
): Promise<ServiceResponse<IWord[] | undefined>> => {
  let data: any | undefined;
  const token = await CognitoService.getToken();
  if (!token) throw new Error('No token found');
  try {
    data = (
      await WordClient.request({
        method: 'post',
        headers: {
          Authorization: `Bearer ${token} `,
        },
        data: {
          query: GET_WORDS,
          variables: {
            input
          },
        },
      })
    )?.data;
    return {
      ...data.data.getWords,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};
export const getWordWith = async (
  input: IGetWordInput
): Promise<ServiceResponse<IWord[] | undefined>> => {
  let data: any | undefined;
  const token = await CognitoService.getToken();
  if (!token) throw new Error('No token found');
  try {
    data = (
      await WordClient.request({
        method: 'post',
        headers: {
          Authorization: `Bearer ${token} `,
        },
        data: {
          query: GET_WORD_WITH,
          variables: {
            input
          },
        },
      })
    )?.data;
    return {
      data: data.data.getWordWith,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};
export const updateWord = async (
  input: IUpdateWordInput
): Promise<ServiceResponse<IWord | undefined>> => {
  let data: any | undefined;
  const token = await CognitoService.getToken();
  if (!token) throw new Error('No token found');
  try {
    data = (
      await WordClient.request({
        method: 'post',
        headers: {
          Authorization: `Bearer ${token} `,
        },
        data: {
          query: UPDATE_WORD,
          variables: {
            input
          },
        },
      })
    )?.data;
    return {
      data: data.data.updateWord,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};
export const deleteWord = async (
  input: IDeleteWordInput
): Promise<ServiceResponse<IWord | undefined>> => {
  let data: any | undefined;
  const token = await CognitoService.getToken();
  if (!token) throw new Error('No token found');
  try {
    data = (
      await WordClient.request({
        method: 'post',
        headers: {
          Authorization: `Bearer ${token} `,
        },
        data: {
          query: DELETE_WORD,
          variables: {
            input
          },
        },
      })
    )?.data;
    return {
      data: data.data.deleteWord,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};
const PlatformService = {
  createWord,
  getWords,
  getWordWith,
  getWord,
  updateWord,
  deleteWord,
};
export default {
  ...PlatformService,
};