import { QUESTION_TYPE_FIELDS_FRAGMENT } from "./base/graphql";

export const GET_BUILD_QUESTION_TYPES = /* GraphQL */ `
  ${QUESTION_TYPE_FIELDS_FRAGMENT}
  query getBuildQuestionTypes($input: GetBuildQuestionTypesInput!) {
    getBuildQuestionTypes(input: $input) {
      data {
        ...QuestionTypeFields
      }
      nextToken
    }
  }
`;
export const GET_BUILD_QUESTION_TYPE = /* GraphQL */ `
  ${QUESTION_TYPE_FIELDS_FRAGMENT}
  query getBuildQuestionType($input: GetBuildQuestionTypeInput!) {
    getBuildQuestionType(input: $input) {
      ...QuestionTypeFields
    }
  }
`;
