import { WORD_FIELDS_FRAGMENT } from "./base/graphql";

export const GET_BUILD_WORDS = /* GraphQL */ `
  ${WORD_FIELDS_FRAGMENT}
  query getBuildWords($input: GetBuildWordsInput!) {
    getBuildWords(input: $input) {
      data {
        ...WordFields
      }
      nextToken
    }
  }
`;
export const GET_BUILD_WORD = /* GraphQL */ `
  ${WORD_FIELDS_FRAGMENT}
  query getBuildWord($input: GetBuildWordInput!) {
    getBuildWord(input: $input) {
      ...WordFields
    }
  }
`;
