import StudentExamClient from "../../client";
import CognitoService from "../../../cognito";
import { ServiceResponse } from "../../../types";
import {
  GET_STUDENT_EXAM,
  CREATE_STUDENT_EXAM,
  UPDATE_STUDENT_EXAM,
  DELETE_STUDENT_EXAM,
  GET_STUDENT_EXAM_WITH,
  GET_STUDENT_EXAMS_BY_GSI3,
} from "./graphql";
import {
  IStudentExam,
  IGetStudentExamInput,
  ICreateStudentExamInput,
  IUpdateStudentExamInput,
  IDeleteStudentExamInput,
  IGetStudentExamsGSI3Input,
} from "./types";


export const createStudentExam = async (
  input: ICreateStudentExamInput
): Promise<ServiceResponse<IStudentExam | undefined>> => {
  let data: any | undefined;
  const token = await CognitoService.getToken();
  if (!token) throw new Error("No token found");
  try {
    data = (
      await StudentExamClient.request({
        method: "post",
        headers: {
          Authorization: `Bearer ${token} `,
        },
        data: {
          query: CREATE_STUDENT_EXAM,
          variables: {
            input,
          },
        },
      })
    )?.data;
    return {
      data: data.data.createStudentExam,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};
export const getStudentExam = async (
  input: IGetStudentExamInput
): Promise<ServiceResponse<IStudentExam | undefined>> => {
  let data: any | undefined;
  const token = await CognitoService.getToken();
  if (!token) throw new Error("No token found");
  try {
    data = (
      await StudentExamClient.request({
        method: "post",
        headers: {
          Authorization: `Bearer ${token} `,
        },
        data: {
          query: GET_STUDENT_EXAM,
          variables: {
            input,
          },
        },
      })
    )?.data;
    return {
      data: data.data.getStudentExam,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};
export const getStudentExamsByGSI3 = async (
  input: IGetStudentExamsGSI3Input
): Promise<ServiceResponse<IStudentExam[] | undefined>> => {
  let data: any | undefined;
  const token = await CognitoService.getToken();
  if (!token) throw new Error("No token found");
  try {
    data = (
      await StudentExamClient.request({
        method: "post",
        headers: {
          Authorization: `Bearer ${token} `,
        },
        data: {
          query: GET_STUDENT_EXAMS_BY_GSI3,
          variables: {
            input,
          },
        },
      })
    )?.data;
    return {
      ...data.data.getStudentExamsByGSI3,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};
export const getStudentExamWith = async (
  input: IGetStudentExamInput
): Promise<ServiceResponse<IStudentExam[] | undefined>> => {
  let data: any | undefined;
  const token = await CognitoService.getToken();
  if (!token) throw new Error("No token found");
  try {
    data = (
      await StudentExamClient.request({
        method: "post",
        headers: {
          Authorization: `Bearer ${token} `,
        },
        data: {
          query: GET_STUDENT_EXAM_WITH,
          variables: {
            input,
          },
        },
      })
    )?.data;
    return {
      ...data.data.getStudentExamWith,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};
export const updateStudentExam = async (
  input: IUpdateStudentExamInput
): Promise<ServiceResponse<IStudentExam | undefined>> => {
  let data: any | undefined;
  const token = await CognitoService.getToken();
  if (!token) throw new Error("No token found");
  try {
    data = (
      await StudentExamClient.request({
        method: "post",
        headers: {
          Authorization: `Bearer ${token} `,
        },
        data: {
          query: UPDATE_STUDENT_EXAM,
          variables: {
            input,
          },
        },
      })
    )?.data;
    return {
      data: data.data.updateStudentExam,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};
export const deleteStudentExam = async (
  input: IDeleteStudentExamInput
): Promise<ServiceResponse<IStudentExam | undefined>> => {
  let data: any | undefined;
  const token = await CognitoService.getToken();
  if (!token) throw new Error("No token found");
  try {
    data = (
      await StudentExamClient.request({
        method: "post",
        headers: {
          Authorization: `Bearer ${token} `,
        },
        data: {
          query: DELETE_STUDENT_EXAM,
          variables: {
            input,
          },
        },
      })
    )?.data;
    return {
      data: data.data.deleteStudentExam,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};

const PlatformService = {
  createStudentExam,
  getStudentExamsByGSI3,
  getStudentExamWith,
  getStudentExam,
  updateStudentExam,
  deleteStudentExam,
};
export default {
  ...PlatformService,
};
