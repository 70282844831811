export const STUDENT_EXAM_PAPER_ANSWER_FIELDS_FRAGMENT = /* GraphQL */ `
  fragment StudentExamPaperAnswerFields on StudentExamPaperAnswer {
    id
    studentExamPaperId
    question {
      id
      tenantId
      name
      type
      text
      options {
        label
        value
      }
      info
      answer
      tolerance
      tips
      showWorkings
      showUploads
      score
    }
    questionType {
      id
      code
      name
      structure {
        type
        label
        optionsIndex
        scrambleOrder
        rowWidth
        enforcedOptionCount
        enforcedOptions {
          label
          value
        }
        generateOptions {
          type
          from
          to
          format
        }
      }
      display
      defaultValue
    }
    paperQuestion {
      id
      paperId
      questionId
      name
      sort
      previousQuestion
    }
    value
    correctValue
    text
    workings
    uploads {
      id
      name
      title
      url
    }
    isCorrect
    score
    isAutoMarked
    instructorComments
    instructorTips
    isLocked
    isToBeReset
    isReset
    isAutoSubmitted
    questionId
    studentExamId
  }
`;
export const CREATE_STUDENT_EXAM_PAPER_ANSWER = /* GraphQL */ `
  ${STUDENT_EXAM_PAPER_ANSWER_FIELDS_FRAGMENT}
  mutation CreateStudentExamPaperAnswer(
    $input: CreateStudentExamPaperAnswerInput!
  ) {
    createStudentExamPaperAnswer(input: $input) {
      ...StudentExamPaperAnswerFields
    }
  }
`;
export const CREATE_OR_UPDATE_STUDENT_EXAM_PAPER_ANSWER_AND_PROCESS = /* GraphQL */ `
  ${STUDENT_EXAM_PAPER_ANSWER_FIELDS_FRAGMENT}
  mutation CreateOrUpdateStudentExamPaperAnswerAndProcess(
    $input: CreateOrUpdateStudentExamPaperAnswerInput!
  ) {
    createOrUpdateStudentExamPaperAnswerAndProcess(input: $input) {
      ...StudentExamPaperAnswerFields
    }
  }
`;
export const GET_STUDENT_EXAM_PAPER_ANSWER = /* GraphQL */ `
  ${STUDENT_EXAM_PAPER_ANSWER_FIELDS_FRAGMENT}
  query GetStudentExamPaperAnswer($input: GetStudentExamPaperAnswerInput!) {
    getStudentExamPaperAnswer(input: $input) {
      ...StudentExamPaperAnswerFields
    }
  }
`;
export const GET_STUDENT_EXAM_PAPER_ANSWERS = /* GraphQL */ `
  ${STUDENT_EXAM_PAPER_ANSWER_FIELDS_FRAGMENT}
  query GetStudentExamPaperAnswers($input: GetStudentExamPaperAnswersInput!) {
    getStudentExamPaperAnswers(input: $input) {
      data {
        ...StudentExamPaperAnswerFields
      }
      nextToken
    }
  }
`;
export const GET_STUDENT_EXAM_PAPER_ANSWER_WITH = /* GraphQL */ `
  ${STUDENT_EXAM_PAPER_ANSWER_FIELDS_FRAGMENT}
  query GetStudentExamPaperAnswerWith($input: GetStudentExamPaperAnswerInput!) {
    getStudentExamPaperAnswerWith(input: $input) {
      ...StudentExamPaperAnswerFields
    }
  }
`;
export const UPDATE_STUDENT_EXAM_PAPER_ANSWER = /* GraphQL */ `
  ${STUDENT_EXAM_PAPER_ANSWER_FIELDS_FRAGMENT}
  mutation UpdateStudentExamPaperAnswer(
    $input: UpdateStudentExamPaperAnswerInput!
  ) {
    updateStudentExamPaperAnswer(input: $input) {
      ...StudentExamPaperAnswerFields
    }
  }
`;
export const DELETE_STUDENT_EXAM_PAPER_ANSWER = /* GraphQL */ `
  ${STUDENT_EXAM_PAPER_ANSWER_FIELDS_FRAGMENT}
  mutation DeleteStudentExamPaperAnswer(
    $input: DeleteStudentExamPaperAnswerInput!
  ) {
    deleteStudentExamPaperAnswer(input: $input) {
      ...StudentExamPaperAnswerFields
    }
  }
`;
