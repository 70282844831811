export const MODULE_FIELDS_FRAGMENT = /* GraphQL */ `
  fragment ModuleFields on Module {
    id
    tenantId
    name
    shortName
    slug

    summary
    objective
    instructions
    hours
    youWillNeed
    learnt
    video

    topics {
      topicId
      name
      sort
    }

    papers {
      id
      paperId
      moduleId
      name
    }

    cb
    sb
    co
    so
  }
`;

export const CREATE_MODULE = /* GraphQL */ `
  ${MODULE_FIELDS_FRAGMENT}
  mutation CreateModule($input: CreateModuleInput!) {
    createModule(input: $input) {
      ...ModuleFields
    }
  }
`;

export const UPDATE_MODULE = /* GraphQL */ `
  ${MODULE_FIELDS_FRAGMENT}
  mutation UpdateModule($input: UpdateModuleInput!) {
    updateModule(input: $input) {
      ...ModuleFields
    }
  }
`;
export const DELETE_MODULE = /* GraphQL */ `
  ${MODULE_FIELDS_FRAGMENT}
  mutation DeleteModule($input: DeleteModuleInput!) {
    deleteModule(input: $input) {
      ...ModuleFields
    }
  }
`;

export const GET_MODULE = /* GraphQL */ `
  ${MODULE_FIELDS_FRAGMENT}
  query GetModule($input: GetModuleInput!) {
    getModule(input: $input) {
      ...ModuleFields
    }
  }
`;

export const GET_MODULE_WITH = /* GraphQL */ `
  ${MODULE_FIELDS_FRAGMENT}
  query GetModuleWith($input: GetModuleInput!) {
    getModuleWith(input: $input) {
      ...ModuleFields
    }
  }
`;

export const GET_BY_MODULE_NAME = /* GraphQL */ `
  ${MODULE_FIELDS_FRAGMENT}
  query GetByModuleName($input: GetByNameModuleInput!) {
    getByModuleName(input: $input) {
      data {
        ...ModuleFields
      }
      nextToken
    }
  }
`;
