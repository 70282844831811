import React, { useState } from 'react'

import Button from '../button';
import shouldHighlight from './helpers/shouldHighlight';
import { usePartnerContext } from '../../context/partner';
import { PartnerColors } from '../../context/partner/types';

export type TextAreaProps = {
    rows: number;
}

export type TextProps = {
    name?: string;
    value?: string;
    className?: string;
    onChange?: React.ChangeEventHandler<any> | undefined;
    correctAnswer?: string;
    label?: string;
    disabled?: boolean;
    readOnly?: boolean;
    placeholder?: string;
    width?: string;
    type?: string;
    textAreaProps?: TextAreaProps;
    highlightColor?: keyof PartnerColors;
    clearable?: boolean;
}

export default function Text({ name, clearable = false, value, correctAnswer, disabled = false, className = "p-3 border border-solid rounded-md w-full", readOnly, placeholder, onChange, width = "w-full", label, type = "text", textAreaProps, highlightColor }: TextProps) {

    const { colors } = usePartnerContext();

    const [focus, setFocus] = useState(false);
    const { rows } = textAreaProps || { rows: 6 };

    let component;

    if (type === "textarea") {
        component = <textarea value={value || ""} rows={rows} disabled={disabled || readOnly} style={{ borderColor: shouldHighlight(value, correctAnswer, colors, highlightColor).color }} className={className} placeholder={placeholder || label} onChange={onChange} />
    } else {
        component = <input value={value || ""} onBlur={() => { setFocus(false) }} onFocus={() => { setFocus(true) }} disabled={disabled || readOnly} style={{ borderColor: shouldHighlight(value, correctAnswer, colors, highlightColor).color }} className={className} type={type} placeholder={placeholder || label} onChange={onChange} />
    }

    return (
        <div className={`${width} flex flex-col space-y-2 justify-evenly`}>
            {label && <p className={"font-bold"}>{`${label}`}</p>}
            <div className={"flex flex-row"}>
                {component}
                {(clearable && value) && <div style={{ position: "relative", width: 0, left: "-30px", top: "15px" }}>
                    <Button style={{ position: "relative", padding: 0 }} noShadow color={"white"} iconColor={"primary"} icon={"close"} onClick={() => { if (onChange) { onChange({ target: { value: "" } } as any) } }} />
                </div>}
            </div>
        </div>
    )
}
