import { EXAM_FIELDS_FRAGMENT } from "./base/graphql";

export const GET_BUILD_EXAMS = /* GraphQL */ `
  ${EXAM_FIELDS_FRAGMENT}
  query getBuildExams($input: GetBuildExamsInput!) {
    getBuildExams(input: $input) {
      data {
        ...ExamFields
      }
      nextToken
    }
  }
`;
export const GET_BUILD_EXAM = /* GraphQL */ `
  ${EXAM_FIELDS_FRAGMENT}
  query getBuildExam($input: GetBuildExamInput!) {
    getBuildExam(input: $input) {
      ...ExamFields
    }
  }
`;
