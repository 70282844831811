export const WORD_FIELDS_FRAGMENT = /* GraphQL */ `
  fragment WordFields on Word {
    id
key
definition
  }
`;
export const CREATE_WORD = /* GraphQL */ `
  ${WORD_FIELDS_FRAGMENT}
  mutation CreateWord($input: CreateWordInput!) {
    createWord(input: $input) {
      ...WordFields
    }
  }
`;
export const GET_WORD = /* GraphQL */ `
  ${WORD_FIELDS_FRAGMENT}
  query GetWord($input: GetWordInput!) {
    getWord(input: $input) {
      ...WordFields
    }
  }
`;
export const GET_WORDS = /* GraphQL */ `
  ${WORD_FIELDS_FRAGMENT}
  query GetWords($input: GetWordsInput!) {
    getWords(input: $input) {
      data {
        ...WordFields
      }
      nextToken
    }
  }
`;
export const GET_WORD_WITH = /* GraphQL */ `
  ${WORD_FIELDS_FRAGMENT}
  query GetWordWith($input: GetWordWithInput!) {
    getWordWith(input: $input) {
      ...WordFields
    }
  }
`;
export const UPDATE_WORD = /* GraphQL */ `
  ${WORD_FIELDS_FRAGMENT}
  mutation UpdateWord($input: UpdateWordInput!) {
    updateWord(input: $input) {
      ...WordFields
    }
  }
`;
export const DELETE_WORD = /* GraphQL */ `
  ${WORD_FIELDS_FRAGMENT}
  mutation DeleteWord($input: DeleteWordInput!) {
    deleteWord(input: $input) {
      ...WordFields
    }
  }
`;