import React from 'react'
import Color from 'color';

import Loader from './loader';
import UTIcon, { UTIconProps } from './icons';
import { usePartnerContext } from '../context/partner';
import { PartnerColors } from '../context/partner/types';

type Props = {
    children?: React.ReactNode;
    onClick?: (e: any) => void;
    type?: "button" | "submit" | "reset";
    className?: string;
    disabled?: boolean;
    loading?: boolean;
    padding?: string;
    variant?: string;
    style?: any;
    color?: keyof PartnerColors;
    textColor?: keyof PartnerColors;
    icon?: UTIconProps["type"];
    iconColor?: keyof PartnerColors;
    align?: "left" | "center" | "right";
    noShadow?: boolean;
}

export default function Button({
    onClick,
    className,
    disabled = false,
    loading = false,
    type = "button",
    padding = "px-4 py-1.5",
    variant = "primary",
    style = {},
    icon,
    children,
    iconColor: iColor,
    color,
    textColor: tColor,
    align = "center",
    noShadow = false,
}: Props) {

    const { colors } = usePartnerContext();

    let textColor = colors?.primaryText;
    let mainColor = colors?.primary;
    // let borderColor = Color(colors?.gray2).darken(0.2).hex();
    let borderColor = colors?.primary;
    let iconColor = iColor || "primaryText";
    let boxShadownEnabled = true;

    if (variant === 'secondary') {
        mainColor = colors?.secondary;
        borderColor = Color(colors?.secondary).darken(0.2).hex();
        textColor = colors.secondaryText
        iconColor = "secondaryText";
    }
    if (variant === 'success') {
        mainColor = colors?.success;
        borderColor = Color(colors?.success).darken(0.2).hex();
        textColor = colors.successText
        iconColor = "successText";
    }
    if (variant === 'danger') {
        mainColor = colors?.danger;
        borderColor = Color(colors?.danger).darken(0.2).hex();
        textColor = colors.dangerText
        iconColor = "dangerText";
    }
    if (variant === 'warning') {
        mainColor = colors?.warning;
        borderColor = Color(colors?.warning).hex();
        textColor = colors.warningText
        iconColor = "warningText";
    }
    if (variant === 'info') {
        mainColor = colors?.info;
        borderColor = Color(colors?.info).darken(0.2).hex();
        textColor = Color(colors?.info).darken(0.5).hex();
        iconColor = "black";
    }
    if (variant === 'ghost') {
        mainColor = 'transparent';
        borderColor = "transparent";
        textColor = colors?.textColor;
        iconColor = "textColor";
        boxShadownEnabled = false;
    }
    if (variant === 'gray') {
        mainColor = colors?.gray2;
        borderColor = Color(colors?.gray2).darken(0.2).hex();
        textColor = colors?.textColor;
        iconColor = "textColor";
    }

    if (noShadow) {
        boxShadownEnabled = false;
    }

    mainColor = color ? colors[color] : mainColor;
    textColor = tColor ? colors[tColor] : textColor;

    const alignmentClass = align === "left" ? "justify-start" : align === "right" ? "justify-end" : "justify-center";

    return (
        <button
            type={type}
            onClick={onClick}
            className={`relative ${padding} border-solid ${className} ${disabled ? 'opacity-50' : ''}`}
            style={{
                borderColor, backgroundColor: mainColor, color: textColor, ...style,
                boxShadow: boxShadownEnabled ? `1.5px 1.5px 1px rgba(0,0,0,0.25)` : undefined
            }}
            disabled={disabled || loading}
        >
            {loading && <div className={"absolute top-0 left-0 w-full h-full flex justify-center items-center "}>
                <span className={"animate-pulse relative"}>
                    <Loader />
                </span>
            </div>}
            <div className={`flex ${alignmentClass} items-center`} style={{ opacity: loading ? 0 : 1 }}>
                {icon && <UTIcon color={colors[iconColor]} type={icon} size={18} className={children ? "mr-2" : ""} />}
                {children}
            </div>
        </button >
    )
}
