import CognitoService from "../../cognito";
import { ServiceResponse } from "../../types";
import Base from "./base";
import { CREATE_OR_UPDATE_STUDENT_EXAM_PAPER_ANSWER_AND_PROCESS } from "./base/graphql";
import { ICreateOrUpdateStudentExamPaperAnswerInput, IStudentExamPaperAnswer } from "./base/types";
import StudentExamPaperAnswerClient from "../client";

export const createOrUpdateStudentExamPaperAnswerAndProcess = async (
    input: ICreateOrUpdateStudentExamPaperAnswerInput
): Promise<ServiceResponse<IStudentExamPaperAnswer | undefined>> => {
    let data: any | undefined;
    const token = await CognitoService.getToken();
    if (!token) throw new Error('No token found');
    try {
        data = (
            await StudentExamPaperAnswerClient.request({
                method: 'post',
                headers: {
                    Authorization: `Bearer ${token} `,
                },
                data: {
                    query: CREATE_OR_UPDATE_STUDENT_EXAM_PAPER_ANSWER_AND_PROCESS,
                    variables: {
                        input
                    },
                },
            })
        )?.data;
        return {
            data: data.data.createOrUpdateStudentExamPaperAnswerAndProcess,
            success: true,
        }
    } catch (err) {
        return {
            success: false,
        };
    }
};

export const StudentExamPaperAnswerService = {
    createOrUpdateStudentExamPaperAnswerAndProcess,
    ...Base
};

export default StudentExamPaperAnswerService;