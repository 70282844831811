import { Link } from 'gatsby';
import React, { useMemo } from 'react'

import Searcher from '../../components/searcher';
import { IFullTopic, IFullModule, useCourseContext } from '../../context/course';

const COURSE_SEARCH_TOOGLE = true;

export default function Search() {

    const { course } = useCourseContext();

    const [search, setSearch] = React.useState("");

    const searchItems = useMemo(() => {

        if (search === "") return [];

        if (!course) return [];

        const modules = (course?.modules.map((m: IFullModule) => (
            {
                ...m,
                url: `/classroom/${course.slug}/${m.slug}`
            }
        )) || []);

        const topics = modules.map((m) => m.topics.map((mt: IFullTopic) => ({
            ...mt,
            url: `/classroom/${course.slug}/${m.slug}/${mt.slug}`
        }))).flat();

        const subtopics: any[] = topics.map((t) => t.subTopics.map((st) => ({
            ...st,
            url: `${t.url}/${st.slug}`
        })).flat()).flat();

        const items: (Partial<{
            name: string;
            url: string;
        }>[]) = [
                ...modules,
                ...topics,
                ...subtopics
            ];

        return items.filter((item) => {
            return item.name?.toLowerCase().includes(search.toLowerCase());
        }).splice(0, 8);

    }, [search]);

    if (!course || !COURSE_SEARCH_TOOGLE) return <></>;

    return (<div className={"relative"}>
        <Searcher
            key={"HeaderSearcher"}
            value={search}
            placeholder={"Search"}
            className={`
                text-sm
                bg-transparent border border-white rounded-md
                py-2 px-4
            `}
            onChange={(q) => { setSearch(q) }}
        />
        {searchItems.length > 0 && <div className={"absolute top-full left-0 w-full bg-white text-black rounded-md shadow-md mt-1 z-50"}>
            {searchItems.map((item, index) => {
                return (
                    <Link key={item.name} to={item.url || "/404"} className={"block py-2 px-4 hover:bg-gray-100"}
                        onClick={() => { setSearch("") }}
                    >
                        {item.name}
                    </Link>
                )
            })}
        </div>}
    </div>
    )
}
