
export const QUESTION_FIELDS_FRAGMENT = /* GraphQL */ `
  fragment QuestionFields on Question {
    id
    tenantId
    name
    type
    text
    options {
      label
      value
    }
    info
    tolerance
    tips
    showWorkings
    showUploads
    score
    cb
    sb
    co
    so
  }
`;

export const CREATE_QUESTION = /* GraphQL */ `
  ${QUESTION_FIELDS_FRAGMENT}
  mutation CreateQuestion($input: CreateQuestionInput!) {
      createQuestion(input: $input) {
        ...QuestionFields
      }
  }
`;

export const UPDATE_QUESTION = /* GraphQL */ `
  ${QUESTION_FIELDS_FRAGMENT}
  mutation UpdateQuestion($input: UpdateQuestionInput!) {
      updateQuestion(input: $input) {
        ...QuestionFields
      }
  }
`;
export const DELETE_QUESTION = /* GraphQL */ `
  ${QUESTION_FIELDS_FRAGMENT}
  mutation DeleteQuestion($input: DeleteQuestionInput!) {
      deleteQuestion(input: $input) {
        ...QuestionFields
      }
  }
`;

export const GET_QUESTION = /* GraphQL */ `
  ${QUESTION_FIELDS_FRAGMENT}
  query GetQuestion($input: GetQuestionInput!){
      getQuestion(input: $input) {
          ...QuestionFields
      }
  }
`;

export const GET_QUESTION_WITH = /* GraphQL */ `
  ${QUESTION_FIELDS_FRAGMENT}
  query GetQuestionWith($input: GetQuestionInput!){
      getQuestionWith(input: $input) {
          ...QuestionFields
      }
  }
`;

export const GET_BY_QUESTION_REF = /* GraphQL */ `
  ${QUESTION_FIELDS_FRAGMENT}
  query GetByQuestionRef($input: GetByRefQuestionInput!){
    getByQuestionRef(input: $input) {
       data {
      ...QuestionFields
      }
      nextToken
    }
  }
`;
