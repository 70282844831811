import ServiceDeskClient from "../../client";
import CognitoService from "../../../cognito";
import { IMessage } from "./types";

import { GET_MESSAGES, CREATE_MESSAGE } from "./graphql";

export const getMessages = async (
  conversationId: string,
  mailboxId: string,
  tenantId: string
) => {
  let data: any | undefined;
  try {
    const token = await CognitoService.getToken();
    data = (
      await ServiceDeskClient.request({
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          query: GET_MESSAGES,
          variables: {
            input: { conversationId, mailboxId, tenantId },
          },
        },
      })
    )?.data;

    return {
      ...data.data.getMessages,
      success: true,
    };
  } catch (err) {
    console.error("getMessages", err);
    return {
      success: false,
    };
  }
};

export const createMessage = async (message: IMessage) => {
  let data: any | undefined;
  try {
    const token = await CognitoService.getToken();

    data = (
      await ServiceDeskClient.request({
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          query: CREATE_MESSAGE,
          variables: {
            input: message,
          },
        },
      })
    )?.data;

    return {
      data: data.data.createMessage,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};

export default {
  getMessages,
  createMessage,
};
