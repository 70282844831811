import React from 'react'

import UTIcon from './icons';
import { usePartnerContext } from '../context/partner';
import { PartnerColors } from '../context/partner/types';

interface Props {
    className?: string;
    size?: number;
    color?: keyof PartnerColors;
}

export default function Loader({ className, size = 25, color }: Props) {

    const { colors } = usePartnerContext();

    const hex = colors[color || "textColor"];

    return (
        <div className={`${className}`}>
            <UTIcon type={"spinner"} size={size} className={"animate-spin"} color={hex} />
        </div>
    )
}
