import axios from 'axios';
import { PLATFORM_API } from '../../config-global';

const PlatformClient = axios.create({ baseURL: PLATFORM_API });

PlatformClient.interceptors.response.use(
  (res) => res,
  (error) => {
    console.error('PlatformClient', 'interceptor', error);
  }
);

export default PlatformClient;