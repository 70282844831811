export const GLOSSARY_FIELDS_FRAGMENT = /* GraphQL */ `
  fragment GlossaryFields on Glossary {
    id
title
words {
  id
  glossaryId
  wordId
}
  }
`;
export const CREATE_GLOSSARY = /* GraphQL */ `
  ${GLOSSARY_FIELDS_FRAGMENT}
  mutation CreateGlossary($input: CreateGlossaryInput!) {
    createGlossary(input: $input) {
      ...GlossaryFields
    }
  }
`;
export const GET_GLOSSARY = /* GraphQL */ `
  ${GLOSSARY_FIELDS_FRAGMENT}
  query GetGlossary($input: GetGlossaryInput!) {
    getGlossary(input: $input) {
      ...GlossaryFields
    }
  }
`;
export const GET_ALL_GLOSSARIES = /* GraphQL */ `
  ${GLOSSARY_FIELDS_FRAGMENT}
  query GetAllGlossaries($input: GetAllGlossariesInput!) {
    getAllGlossaries(input: $input) {
      data {
        ...GlossaryFields
      }
      nextToken
    }
  }
`;
export const GET_GLOSSARY_WITH = /* GraphQL */ `
  ${GLOSSARY_FIELDS_FRAGMENT}
  query GetGlossaryWith($input: GetGlossaryInput!) {
    getGlossaryWith(input: $input) {
      ...GlossaryFields
    }
  }
`;
export const UPDATE_GLOSSARY = /* GraphQL */ `
  ${GLOSSARY_FIELDS_FRAGMENT}
  mutation UpdateGlossary($input: UpdateGlossaryInput!) {
    updateGlossary(input: $input) {
      ...GlossaryFields
    }
  }
`;
export const DELETE_GLOSSARY = /* GraphQL */ `
  ${GLOSSARY_FIELDS_FRAGMENT}
  mutation DeleteGlossary($input: DeleteGlossaryInput!) {
    deleteGlossary(input: $input) {
      ...GlossaryFields
    }
  }
`;