
export const EXAM_FIELDS_FRAGMENT = /* GraphQL */ `
  fragment ExamFields on Exam {
    id
    name
    slug
    type
    access
    requirements
    papers {
      id
      paperId
      examId
      name
    }
    openBook
    cb
    sb
    co
    so
  }
`;

export const CREATE_EXAM = /* GraphQL */ `
  ${EXAM_FIELDS_FRAGMENT}
  mutation CreateExam($input: CreateExamInput!) {
      createExam(input: $input) {
        ...ExamFields
      }
  }
`;

export const UPDATE_EXAM = /* GraphQL */ `
  ${EXAM_FIELDS_FRAGMENT}
  mutation UpdateExam($input: UpdateExamInput!) {
      updateExam(input: $input) {
        ...ExamFields
      }
  }
`;
export const DELETE_EXAM = /* GraphQL */ `
  ${EXAM_FIELDS_FRAGMENT}
  mutation DeleteExam($input: DeleteExamInput!) {
      deleteExam(input: $input) {
        ...ExamFields
      }
  }
`;

export const GET_EXAM = /* GraphQL */ `
  ${EXAM_FIELDS_FRAGMENT}
  query GetExam($input: GetExamInput!){
      getExam(input: $input) {
          ...ExamFields
      }
  }
`;

export const GET_EXAM_WITH = /* GraphQL */ `
  ${EXAM_FIELDS_FRAGMENT}
  query GetExamWith($input: GetExamInput!){
      getExamWith(input: $input) {
          ...ExamFields
      }
  }
`;

export const GET_BY_EXAM_NAME = /* GraphQL */ `
  ${EXAM_FIELDS_FRAGMENT}
  query GetByExamName($input: GetByNameExamInput!){
    getByExamName(input: $input) {
       data {
      ...ExamFields
      }
      nextToken
    }
  }
`;
