import {
  AuthUser,
  SignInOutput,
  getCurrentUser,
  fetchAuthSession,
  fetchUserAttributes,
  signIn as signInCognito,
  FetchUserAttributesOutput,
  signOut as signOutCognito,
  confirmSignIn as confirmSignInCognito,
} from "@aws-amplify/auth";

import { ServiceResponse } from "../types";

export type CognitoUser = Pick<AuthUser, "username"> &
  FetchUserAttributesOutput;

const checkLoggedIn = async (): Promise<boolean> => {
  try {
    const session = await fetchAuthSession();
    return !!session.userSub;
  } catch (e) {
    console.error("checkLoggedIn", e);
    return false;
  }
};
const getUser = async (): Promise<CognitoUser | undefined> => {
  try {
    const cognitoUser = await getCurrentUser();
    const attr = await fetchUserAttributes();

    if (cognitoUser && attr) {
      return {
        ...{
          username: cognitoUser.username,
        },
        ...attr,
      };
    }
  } catch (e) {
    console.error("getUser", e);
  }
  return undefined;
};
const signIn = async (
  username: string
): Promise<ServiceResponse<SignInOutput>> => {
  try {
    const signInCognitoResponse = await signInCognito({
      username,
      options: {
        authFlowType: "CUSTOM_WITHOUT_SRP",
      },
    });
    return {
      success: true,
      data: signInCognitoResponse,
    };
  } catch (e: any) {
    console.error("cognito", "login", e);
    return {
      success: false,
      errors: [{ code: "Error", message: "Could not sign in to Cognito." }],
    };
  }
};
const signOut = async () => {
  try {
    await signOutCognito();
  } catch (e) {
    console.error("signOut", e);
  }
};
const confirmSignIn = async (
  challengeResponse: string
): Promise<ServiceResponse<boolean>> => {
  try {
    const res = await confirmSignInCognito({
      challengeResponse,
    });
    if (res?.isSignedIn) {
      return { success: true, data: true };
    }
    return {
      success: false,
      errors: [{ code: "Error", message: "Code failed." }],
    };
  } catch (e) {
    console.error("confirmSignIn", e);
    return {
      success: false,
      errors: [{ code: "Error", message: "Could not confirm sign in." }],
    };
  }
};
const getToken = async () => {
  try {
    const session = await fetchAuthSession();
    return session.tokens?.idToken?.toString();
  } catch (e) {
    console.error("getToken", e);
    return null;
  }
};
const CognitoService = {
  getUser,
  signIn,
  signOut,
  confirmSignIn,
  getToken,
  checkLoggedIn,
};
export default CognitoService;
