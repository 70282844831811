import _ from "lodash";
import React, { useMemo } from "react";

import Alert from "./alert";
import Countdown from "./exam/Countdown";
import { usePartnerContext } from "../context/partner";

export type IMessage = {
    id: string,
    type: "info" | "success" | "warning" | "danger",
    text: String,
    dismissible: boolean,
    duration: number | "permanent",
    createdOn: string,
    group: "signIn" | "submitAnswer" | "submitPaper" | "",
    loud: boolean,
}

type MessageProps = {
    message: IMessage,
    messageIndex: number,
    setMessages: React.Dispatch<React.SetStateAction<any[]>>,
    messages: IMessage[],
}

const Message = ({ message, messageIndex, setMessages, messages }: MessageProps) => {

    const { colors } = usePartnerContext();

    const [paused, setPaused] = React.useState<Record<string, boolean>>({});

    const onMouseEnter = () => {
        setPaused({ ...paused, [message.id]: true });
    };
    const onMouseLeave = () => {
        const currentPaused = { ...paused };
        delete currentPaused[message.id];
        setPaused(currentPaused);
    }

    const dismiss = () => {

        if (!message.dismissible) {
            return;
        }

        setMessages(_.filter(messages, (m) => m.id !== message.id))
    };

    const snackbarStyle = useMemo(() => {

        switch (message.type) {
            case "info":
                return {
                    borderLeft: "10px solid",
                    borderColor: colors.info,
                    backgroundColor: colors.white,
                    color: colors.textColor,
                }
            case "success":
                return {
                    borderLeft: "10px solid",
                    borderColor: colors.success,
                    backgroundColor: colors.white,
                    color: colors.textColor,
                }
            case "warning":
                return {
                    borderLeft: "10px solid",
                    borderColor: colors.warning,
                    backgroundColor: colors.white,
                    color: colors.textColor,
                }
            case "danger":
                return {
                    borderLeft: "10px solid",
                    borderColor: colors.danger,
                    backgroundColor: colors.white,
                    color: colors.textColor,
                }
        }

    }, [message.type]);

    if (message.loud) {
        return <div
            className={`fixed z-50`}
            style={{ top: "25%", left: "25%", width: "50%" }}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={dismiss}
        >
            <Alert heading={_.capitalize(message.type)} color={message.type}>
                <span>
                    {message.text}
                </span>
            </Alert>
        </div>
    }

    return (
        <div
            className={`fixed z-50`}
            style={{ bottom: `${(messageIndex * 80) + 20}px`, right: "20px", width: "300px" }}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <div
                className={"flex flex-wrap items-center justify-center font-medium py-3 px-3"}
                style={{
                    ...snackbarStyle,
                    boxShadow: `0 1px 2px 1px rgba(0,0,0,0.15)`,
                    cursor: message.dismissible ? "pointer" : "default"
                }}
                onClick={dismiss}
            >
                {/* close icon */}
                {/* {message.dismissible &&
                    <div className={"absolute top-[-8px] left-[-8px] cursor-pointer"}>
                        <UTIcon type="closeCircle" className="bg-white" size={16} onClick={dismiss} />
                    </div>
                } */}

                {/* message text */}
                <span>
                    {message.text}
                </span>

                {message.duration !== "permanent" && <div className={"w-full mt-2"}>
                    <Countdown
                        key={message.id + message.createdOn}
                        startedOn={message.createdOn}
                        time={((1 / 60) * (message.duration / 1000))}
                        paused={paused[message.id]}
                        display={{ type: "bar", options: { height: 1 } }}
                        timeoutExpired={
                            () => {
                                setMessages(_.filter(messages, (m) => m.id !== message.id));
                                const currentPaused = { ...paused };
                                delete currentPaused[message.id];
                                setPaused(currentPaused);
                            }
                        } />
                </div>}
            </div>
        </div>)
}

export default Message;