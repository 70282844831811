
import ExamClient from "../../client";
import CognitoService from "../../../cognito";
import { ServiceResponse } from "../../../types";
import {
  GET_EXAM,
  CREATE_EXAM,
  DELETE_EXAM,
  UPDATE_EXAM,
  GET_EXAM_WITH,
  GET_BY_EXAM_NAME,

} from "./graphql";
import {
  IExam,
  GetExamInput,
  CreateExamInput,
  UpdateExamInput,
  DeleteExamInput,
  GetByExamNameInput,

} from "./types";


export const getExam = async (input: GetExamInput): Promise<ServiceResponse<IExam>> => {
  let data: any | undefined;
  try {
    const token = await CognitoService.getToken();
    data = (
      await ExamClient.request({
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          query: GET_EXAM,
          variables: {
            input
          }
        }
      }))?.data;
    return {
      data: data.data.getExam,
      success: true,
    } as ServiceResponse<IExam>;
  } catch (err) {
    if (data?.data?.errors) {
      console.error('getExam', err);
    }
    return {
      success: false,
    } as ServiceResponse<IExam>;
  }
};

export const getExamWith = async (input: GetExamInput): Promise<ServiceResponse<IExam>> => {
  let data: any | undefined;
  try {
    const token = await CognitoService.getToken();
    data = (
      await ExamClient.request({
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          query: GET_EXAM_WITH,
          variables: {
            input
          }
        }
      }))?.data;
    return {
      data: data.data.getExamWith,
      success: true,
    }
  } catch (err) {
    if (data?.data?.errors) {
      console.error('getExam', err);
    }
    return {
      success: false,
    }
  }
};

export const createExam = async (input: CreateExamInput): Promise<ServiceResponse<IExam>> => {
  let data: any | undefined;;
  try {
    const token = await CognitoService.getToken();
    data = (
      await ExamClient.request({
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`
        },
        data: {
          query: CREATE_EXAM,
          variables: {
            input
          }
        }
      }))?.data;
    return {
      data: data.data.createExam,
      success: true,
    }
  } catch (err) {
    if (data?.data?.errors) {
      console.error('createExam', err);
    }
    return {
      success: false,
    }
  }
};

export const updateExam = async (input: UpdateExamInput): Promise<ServiceResponse<IExam>> => {
  let data: any | undefined;
  try {
    const token = await CognitoService.getToken();
    data = (
      await ExamClient.request({
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`
        },
        data: {
          query: UPDATE_EXAM,
          variables: {
            input
          }
        }
      }))?.data;
    return {
      data: data.data.updateExam,
      success: true,
    }
  } catch (err) {
    if (data?.data?.errors) {
      console.error('updateExam', err);
    }
    return {
      success: false,
    }
  }
};

export const deleteExam = async (input: DeleteExamInput): Promise<ServiceResponse<IExam>> => {
  let data: any | undefined;
  try {
    const token = await CognitoService.getToken();
    data = (
      await ExamClient.request({
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`
        },
        data: {
          query: DELETE_EXAM,
          variables: {
            input
          }
        }
      }))?.data;;
    return {
      data: data.data.deleteExam,
      success: true,
    }
  } catch (err) {
    if (data?.data?.errors) {
      console.error('deleteExam', err);
    }
    return {
      success: false,
    }
  }
};


export const getExamByName = async (input: GetByExamNameInput): Promise<ServiceResponse<IExam[]>> => {
  let data: any | undefined;
  try {
    const token = await CognitoService.getToken();
    data = (
      await ExamClient.request({
        method: 'post',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          query: GET_BY_EXAM_NAME,
          variables: {
            input,
          },
        },
      })
    )?.data;
    return {
      ...data.data.getByExamName,
      success: true,
    }
  } catch (err) {
    if (data?.data?.errors) {
      console.error('GetByExamNameInput', err)
    }
    return {
      success: false,
    }
  }
};



export default {
  getExam,
  getExamWith,
  createExam,
  updateExam,
  deleteExam,
  getExamByName
};

