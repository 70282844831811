import axios from "axios";

import Base from "./base/index";
import { PresignedData } from "./types";
import { ServiceResponse } from "../../types";
import { FILE_MANAGER_PRESIGNED_URL } from "../../../config-global";

const createPresignedData = async (
  file: {
    name: string;
    type: string;
  },
  tenantId: string,
  folderPath?: string,
): Promise<ServiceResponse<PresignedData> | undefined> => {
  let data: any | undefined;
  try {
    data = (
      await axios({
        url: FILE_MANAGER_PRESIGNED_URL,
        method: "post",
        data: {
          tenantId,
          name: file.name,
          type: file.type,
          folderPath
        },
      })
    )?.data;

    return {
      data: data.data as PresignedData,
      success: true,
    };
  } catch (err) {
    console.error("getFile", err);
    return {
      success: false,
    };
  }
};

const uploadFile = async (
  presignedData: PresignedData,
  file: File
): Promise<boolean> => {
  try {
    // get form data
    const formData = new FormData();
    Object.keys(presignedData.fields).forEach((key) => {
      formData.append(key, presignedData.fields[key]);
    });
    formData.append("file", file);

    await axios({
      url: presignedData.url,
      method: "post",
      data: formData,
    });

    return true;
  } catch (err) {
    console.error("uploadFile", err);
  }
  return false;
};

const FileService = {
  createPresignedData,
  uploadFile,
};

export default {
  ...Base,
  ...FileService,
};
