import React from "react";
import GlobalLayout from "./layouts/Global";

const Wrapper = ({ element, props }: any) => {
    return (
        <GlobalLayout wrapperProps={props} >
            {element}
        </GlobalLayout>
    );
};

export default Wrapper;
