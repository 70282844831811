import React, { useState, useEffect } from "react";


interface Props {
    value?: string;
    className?: string;
    onChange(q: string): Promise<void> | void;
    placeholder?: string;
}

const Searcher = React.forwardRef(
    (
        {
            value: externalValue,
            onChange: externalOnChange,
            placeholder,
            className
        }: Props,
        ref
    ) => {

        const [internalValue, setInternalValue] = React.useState("");
        const [delay, setDelay] = useState<NodeJS.Timeout | undefined>(undefined);

        useEffect(() => {
            if (!internalValue || !internalValue.length) {
                return;
            }
            setDelay((prev) => {
                if (prev) { clearTimeout(prev) };
                return setTimeout(() => {
                    externalOnChange(internalValue);
                }, 600);
            });
        }, [internalValue])

        const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value || "";
            setInternalValue(value);
        };

        useEffect(() => {
            if (externalValue === undefined) return;
            setInternalValue(externalValue);
        }, [externalValue]);

        return (
            <input
                ref={ref as any}
                value={internalValue}
                className={className}
                placeholder={placeholder}
                onChange={onChange}
            />
        );
    }
);

export default Searcher;