import axios from "axios";
import { LMS_API } from "../../config-global";


const LmsClient = axios.create({
  baseURL: LMS_API,
  timeout: 60000,
});

LmsClient.interceptors.response.use(
  (res) => {
    if (res.data.errors) {
      console.error(
        res?.data?.errors[0]?.path,
        res.data.errors.map((x: any) => x.message).join("\n")
      );
    }
    return res;
  },
  (error) => {
    console.error("LmsClient", "interceptor", error);
  }
);

export default LmsClient;
