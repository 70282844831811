export const FILE_FIELDS_FRAGMENT = /* GraphQL */ `
  fragment FileFields on File {
    id
    name
    title
    url
    cb
    sb
    co
    so
  }
`;

export const CREATE_FILE = `
  ${FILE_FIELDS_FRAGMENT}
  mutation CreateFile($input: CreateFileInput!) {
      createFile(input: $input) {
        ...FileFields
      }
  }
`;

export const UPDATE_FILE = /* GraphQL */ `
  ${FILE_FIELDS_FRAGMENT}
  mutation UpdateFile($input: UpdateFileInput!) {
    updateFile(input: $input) {
      ...FileFields
    }
  }
`;
export const DELETE_FILE = `
  ${FILE_FIELDS_FRAGMENT}
  mutation DeleteFile($input: DeleteFileInput!) {
      deleteFile(input: $input) {
        ...FileFields
      }
  }
`;

export const GET_FILE = /* GraphQL */ `
  ${FILE_FIELDS_FRAGMENT}
  query GetFile($input: GetFileInput!) {
    getFile(input: $input) {
      ...FileFields
    }
  }
`;
export const GET_FILE_WITH = /* GraphQL */ `
  ${FILE_FIELDS_FRAGMENT}
  query GetFileWith($input: GetFileInput!) {
    getFileWith(input: $input) {
      ...FileFields
    }
  }
`;

export const GET_BY_FILE_NAME = /* GraphQL */ `
  ${FILE_FIELDS_FRAGMENT}
  query GetByFileName($input: GetByNameFileInput!) {
    getByFileName(input: $input) {
      data {
        ...FileFields
      }
      nextToken
    }
  }
`;

export const GET_BY_FILE_TITLE = /* GraphQL */ `
  ${FILE_FIELDS_FRAGMENT}
  query GetByFileName($input: GetByTitleFileInput!) {
    getByFileTitle(input: $input) {
      data {
        ...FileFields
      }
      nextToken
    }
  }
`;
