import base from "./base";
import LmsClient from "../client";
import { IQuestionType } from "./base/types";
import { ServiceResponse } from "../../types";
import { TENANT_ID, LMS_API_KEY } from "../../../config-global";
import { GET_BUILD_QUESTION_TYPE, GET_BUILD_QUESTION_TYPES } from "./graphql";
import { GetBuildQuestionTypeInput, GetBuildQuestionTypesInput } from "./types";

const getBuildQuestionTypes = async (
    lastUpdated: string
): Promise<ServiceResponse<IQuestionType[] | undefined>> => {
    let data: any | undefined;
    try {
        const input: GetBuildQuestionTypesInput = {
            tenantId: TENANT_ID,
            so: lastUpdated,
            limit: 1000
        };
        data = (
            await LmsClient.request({
                method: "post",
                headers: {
                    "x-api-key": LMS_API_KEY,
                },
                data: {
                    query: GET_BUILD_QUESTION_TYPES,
                    variables: {
                        input,
                    },
                },
            })
        )?.data;

        return {
            ...data.data.getBuildQuestionTypes,
            success: !!data.data.getBuildQuestionTypes.data,
        };
    } catch (err) {
        console.error("getBuildQuestionTypes", err);
        if (data?.errors) {
            console.error("getBuildQuestionTypes", data?.errors);
        }
        return {
            success: false,
        };
    }
};

const getBuildQuestionType = async (
    id: string
): Promise<ServiceResponse<IQuestionType | undefined>> => {
    let data: any | undefined;

    const input: GetBuildQuestionTypeInput = {
        tenantId: TENANT_ID,
        id,
    };

    try {
        data = (
            await LmsClient.request({
                method: "post",
                headers: {
                    "x-api-key": LMS_API_KEY,
                },
                data: {
                    query: GET_BUILD_QUESTION_TYPE,
                    variables: {
                        input,
                    },
                },
            })
        )?.data;

        return {
            data: data.data.getBuildQuestionType,
            success: !!data.data.getBuildQuestionType,
        };
    } catch (err) {
        console.error("getBuildQuestionType", err, input);
        if (data?.errors) {
            console.error("getBuildQuestionType", data?.errors);
        }
        return {
            success: false,
        };
    }
};

const QuestionTypeService = { ...base, getBuildQuestionTypes, getBuildQuestionType };

export default {
    ...QuestionTypeService,
};
