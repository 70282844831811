import { LMS_API_KEY, TENANT_ID } from "../../../config-global";
import { ServiceResponse } from "../../types";
import LmsBuildClient from "../../../../build/buildClient";
import Base from "./base/index";
import { IExam } from "./base/types";
import { GET_BUILD_EXAM, GET_BUILD_EXAMS } from "./graphql";
import { GetBuildExamInput, GetBuildExamsInput } from "./types";

const getBuildExams = async (
  lastUpdated: string,
  nextToken?: string
): Promise<ServiceResponse<IExam[] | undefined>> => {
  let data: any | undefined;
  try {
    const input: GetBuildExamsInput = {
      tenantId: TENANT_ID,
      so: lastUpdated,
      limit: 1000,
    };

    data = (
      await LmsBuildClient.request({
        method: "post",
        headers: {
          "x-api-key": LMS_API_KEY,
        },
        data: {
          query: GET_BUILD_EXAMS,
          variables: {
            input: {
              ...input,
              nextToken,
            },
          },
        },
      })
    )?.data;

    return {
      ...data.data.getBuildExams,
      success: !!data.data.getBuildExams.data,
    };
  } catch (err) {
    console.error("getBuildExams", err);
    if (data?.errors) {
      console.error("getBuildExams", data?.errors);
    }
    return {
      success: false,
    };
  }
};

const getBuildExam = async (
  id: string
): Promise<ServiceResponse<IExam | undefined>> => {
  let data: any | undefined;

  const input: GetBuildExamInput = {
    tenantId: TENANT_ID,
    id,
  };

  try {
    data = (
      await LmsBuildClient.request({
        method: "post",
        headers: {
          "x-api-key": LMS_API_KEY,
        },
        data: {
          query: GET_BUILD_EXAM,
          variables: {
            input,
          },
        },
      })
    )?.data;

    return {
      data: data.data.getBuildExam,
      success: !!data.data.getBuildExam,
    };
  } catch (err) {
    console.error("getBuildExam", err, input);
    if (data?.errors) {
      console.error("getBuildExam", data?.errors);
    }
    return {
      success: false,
    };
  }
};

const ExamService = { getBuildExams, getBuildExam };

export default {
  ...Base,
  ...ExamService,
};
