import React from 'react'

// @ts-ignore
import RefreshIcon from '../images/refresh-white.png';

export default function DevTools() {

    const handleRefresh = () => {
        fetch('/__refresh', {
            method: 'POST',
        });
    }

    return (
        <>
            <button className='
                fixed
                bottom-5
                left-5
                p-3
                rounded
                bg-pink-500
                text-black
            ' onClick={handleRefresh}>
                <img src={RefreshIcon} className='w-4 h-4 object-contain' alt="Refresh" />
            </button>
        </>
    )
}
