'use client';

import React, { useMemo, useState, useEffect, useContext, createContext } from 'react';

import { useAuthContext } from '../auth';
import { useServiceDeskContext } from '.';
import { TENANT_ID } from '../../config-global';
import CognitoService from '../../services/cognito';
import { IConversation } from '../../services/service-desk/conversation/base/types';
import { UPDATE_CONVERSATION_SUB } from '../../services/service-desk/conversation/graphql';

type ServiceDeskConversationContextType = {
    latestConversation: IConversation | undefined;
};

// CONTEXT
export const ServiceDeskConversationContext = createContext({} as ServiceDeskConversationContextType);

// PROVIDER
type Props = {
    children: React.ReactNode;
};

export function ServiceDeskConversationProvider({ children }: Props) {
    const { lastJsonMessage, sendMessage } = useServiceDeskContext();

    const [latestConversation, setLatestConversation] = useState<IConversation | undefined>(undefined);

    const { student, loadConversations } = useAuthContext();

    const tenant = TENANT_ID;

    // Handle Messages
    useEffect(() => {
        const handle = async () => {
            if (!lastJsonMessage) return;
            if (!tenant || !student?.id) return;
            const type = lastJsonMessage?.type;

            // CONNECTION ACKNOWLEDGEMENT
            if (type === "connection_ack") {
                const token = await CognitoService.getToken();
                // UPDATE CONVERSATION
                sendMessage(JSON.stringify({
                    id: `${student.id}-update-conversation`,
                    type: 'start',
                    payload: {
                        data: JSON.stringify({
                            query: UPDATE_CONVERSATION_SUB,
                            variables: {
                                tenantId: tenant,
                                contactId: student.id
                            }
                        }),
                        extensions: {
                            authorization: {
                                "Authorization": `Bearer ${token}`
                            }
                        }
                    },
                }));
            };

            // DATA RECEIVED
            if (type === "data") {
                const onUpdateConversation = lastJsonMessage?.payload?.data?.onUpdateConversation as (IConversation | undefined);
                if (onUpdateConversation) {
                    setLatestConversation(onUpdateConversation);
                    loadConversations();
                }
            };
        }

        handle();
    }, [lastJsonMessage, tenant, student?.id, sendMessage]);

    const memoizedValue = useMemo(
        () => ({
            latestConversation,
        }),
        [latestConversation]
    );

    return <ServiceDeskConversationContext.Provider value={memoizedValue}>{children}</ServiceDeskConversationContext.Provider>;
}

// HOOK
export const useServiceDeskConversationContext = () => {
    const context = useContext(ServiceDeskConversationContext)
    if (!context)
        throw new Error("useServiceDeskConversationContext context must be use inside ServiceDeskConversationProvider");
    return context;
};

