import Base from "./base/index";
import { IAsset } from "./base/types";
import { ServiceResponse } from "../../types";
import LmsBuildClient from "../../../../build/buildClient";
import { GET_BUILD_ASSET, GET_BUILD_ASSETS } from "./graphql";
import { TENANT_ID, LMS_API_KEY } from "../../../config-global";
import { GetBuildAssetInput, GetBuildAssetsInput } from "./types";

const getBuildAssets = async (
  lastUpdated: string,
  nextToken?: string
): Promise<ServiceResponse<IAsset[] | undefined>> => {
  let data: any | undefined;
  try {
    const input: GetBuildAssetsInput = {
      tenantId: TENANT_ID,
      so: lastUpdated,
      limit: 1000,
    };

    data = (
      await LmsBuildClient.request({
        method: "post",
        headers: {
          "x-api-key": LMS_API_KEY,
        },
        data: {
          query: GET_BUILD_ASSETS,
          variables: {
            input: { ...input, nextToken },
          },
        },
      })
    )?.data;

    return {
      ...data.data.getBuildAssets,
      success: !!data.data.getBuildAssets.data,
    };
  } catch (err) {
    console.error("getBuildAssets", err);
    if (data?.errors) {
      console.error("getBuildAssets", data?.errors);
    }
    return {
      success: false,
    };
  }
};

const getBuildAsset = async (
  id: string
): Promise<ServiceResponse<IAsset | undefined>> => {
  let data: any | undefined;
  try {
    const input: GetBuildAssetInput = {
      tenantId: TENANT_ID,
      id,
    };

    data = (
      await LmsBuildClient.request({
        method: "post",
        headers: {
          "x-api-key": LMS_API_KEY,
        },
        data: {
          query: GET_BUILD_ASSET,
          variables: {
            input,
          },
        },
      })
    )?.data;

    return {
      data: data.data.getBuildAsset,
      success: !!data.data.getBuildAsset,
    };
  } catch (err) {
    console.error("getBuildAsset", {
      error: err,
      gqlErrors: data?.errors,
      data,
    });
    return {
      success: false,
    };
  }
};

const AssetService = { getBuildAssets, getBuildAsset };

export default {
  ...Base,
  ...AssetService,
};
