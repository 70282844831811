import { IStudentExamPaperAnswer } from "../../studentExamPaperAnswer/base/types";
import { IThreshold } from "../../paper/base/types";
import { IStudentExam } from "../../studentExam/base/types";

export type IStudentExamPaper = {
  id: string;
  studentExamId: string;
  partnerId: string;
  paperId: string;
  name: string;
  type: string;
  status: StudentExamPaperStatus;
  studentId: string;
  score: number;
  totalScore: number;
  scoreThresholds: IThreshold;
  startedOn: string;
  time: number;
  completedOn: string;
  answers: [IStudentExamPaperAnswer];
  moduleName: string;
  co: string;
  cb: string;
  so: string;
  sb: string;
};
export type ICreateStudentExamPaperInput = {
  studentExamId: string;
  paperId: string;
  name: string;
  type: string;
  status: StudentExamPaperStatus;
  studentId: string;
  score: number;
  totalScore: number;
  scoreThresholds: IThreshold;
  startedOn?: string;
  time?: string;
  completedOn?: string;
  answers?: [IStudentExamPaperAnswer];
};
export type IUpdateStudentExamPaperInput = {
  id: string;
  partnerId: string;
  studentExamId?: string;
  paperId?: string;
  name?: string;
  type?: string;
  status?: StudentExamPaperStatus;
  studentId?: string;
  score?: number;
  totalScore?: number;
  scoreThresholds?: [IThreshold];
  startedOn?: string;
  time?: string;
  completedOn?: string;
  answers?: [IStudentExamPaperAnswer];
};
export type IDeleteStudentExamPaperInput = {
  id: string;
};
export type IGetStudentExamPaperInput = {
  id: string;
  partnerId: string;
  studentExamId: string;
};
export type IGetStudentExamPapersByGSI2Input = {
  studentExamId: string;
  paperId: string;
};
export type IGetStudentExamPaperByStudentExamIdInput = {
  partnerId: string;
  studentExamId: string;
};

export type ILoadStudentExamPaperResponse = {
  studentExam: IStudentExam;
  studentExamPaper: IStudentExamPaper;
};

export type ILoadStudentExamPaperInput = {
  partnerId: string;
  studentId: string;
  courseId: string;
  examId: string;
  paperId: string;
  partnerName: string;
  moduleName: string;
};

export type IGetStudentExamPapersByGSI1Input = {
  studentId: string;
  status: StudentExamPaperStatus;
};

export enum StudentExamPaperStatus {
  RESET = "RESET",
  IN_PROGRESS = "IN_PROGRESS",
  SUBMITTED = "SUBMITTED",
  PASSED = "PASSED",
  FAILED = "FAILED",
}
