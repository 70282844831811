import Base from "./base/index";
import { LMS_API_KEY, TENANT_ID } from "../../../config-global";
import { ServiceResponse } from "../../types";
import LmsBuildClient from "../../../../build/buildClient";
import { IQuestion } from "./base/types";
import { GET_BUILD_QUESTION, GET_BUILD_QUESTIONS } from "./graphql";
import { GetBuildQuestionInput, GetBuildQuestionsInput } from "./types";

const getBuildQuestions = async (
  lastUpdated: string,
  nextToken?: string
): Promise<ServiceResponse<IQuestion[] | undefined>> => {
  let data: any | undefined;
  try {
    const input: GetBuildQuestionsInput = {
      tenantId: TENANT_ID,
      so: lastUpdated,
      limit: 1000,
    };
    data = (
      await LmsBuildClient.request({
        method: "post",
        headers: {
          "x-api-key": LMS_API_KEY,
        },
        data: {
          query: GET_BUILD_QUESTIONS,
          variables: {
            input: {
              ...input,
              nextToken,
            },
          },
        },
      })
    )?.data;

    return {
      ...data.data.getBuildQuestions,
      success: !!data.data.getBuildQuestions.data,
    };
  } catch (err) {
    console.error("getBuildQuestions", err);
    if (data?.errors) {
      console.error("getBuildQuestions", data?.errors);
    }
    return {
      success: false,
    };
  }
};

const getBuildQuestion = async (
  id: string
): Promise<ServiceResponse<IQuestion | undefined>> => {
  let data: any | undefined;

  const input: GetBuildQuestionInput = {
    tenantId: TENANT_ID,
    id,
  };

  try {
    data = (
      await LmsBuildClient.request({
        method: "post",
        headers: {
          "x-api-key": LMS_API_KEY,
        },
        data: {
          query: GET_BUILD_QUESTION,
          variables: {
            input,
          },
        },
      })
    )?.data;

    return {
      data: data.data.getBuildQuestion,
      success: !!data.data.getBuildQuestion,
    };
  } catch (err) {
    console.error("getBuildQuestion", err, input);
    if (data?.errors) {
      console.error("getBuildQuestion", data?.errors);
    }
    return {
      success: false,
    };
  }
};

const QuestionService = { getBuildQuestions, getBuildQuestion };

export default {
  ...Base,
  ...QuestionService,
};
