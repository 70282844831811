import { PLATFORM_API_KEY, TENANT_ID } from "../../../config-global";
import PlatformClient from "../client";
import Base from "./base";
import { IPartner } from "./base/types";
import { GET_PARTNER_FOR_APP } from "./graphql";

const getPartnerBySubdomain = async (
  subdomain: string
): Promise<IPartner[]> => {
  let data: any;
  try {
    const input = {
      tenantId: TENANT_ID,
      subdomain,
    };

    data = (
      await PlatformClient.request({
        method: "post",
        headers: {
          "x-api-key": PLATFORM_API_KEY,
        },
        data: {
          query: GET_PARTNER_FOR_APP,
          variables: {
            input,
          },
        },
      })
    )?.data;

    return (data.data.getPartnerForApp.data || []) as IPartner[];
  } catch (error) {
    console.error("partner", "getPartnerBySubdomain", error);
    return [];
  }
};

const CustomMethods = {
  getPartnerBySubdomain,
};

const Partner = {
  ...Base,
  ...CustomMethods,
};
export default Partner;
