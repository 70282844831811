import { LMS_API_KEY, TENANT_ID } from "../../../config-global";
import CognitoService from "../../cognito";
import { ServiceResponse } from "../../types";
import LmsBuildClient from "../../../../build/buildClient";
import { IPaper } from "./base/types";
import { GET_BUILD_PAPER, GET_BUILD_PAPERS } from "./graphql";
import { GetBuildPaperInput, GetBuildPapersInput } from "./types";

const getBuildPapers = async (
  lastUpdated: string,
  nextToken?: string
): Promise<ServiceResponse<IPaper[] | undefined>> => {
  let data: any | undefined;
  try {
    const input: GetBuildPapersInput = {
      tenantId: TENANT_ID,
      so: lastUpdated,
      limit: 1000,
    };
    data = (
      await LmsBuildClient.request({
        method: "post",
        headers: {
          "x-api-key": LMS_API_KEY,
        },
        data: {
          query: GET_BUILD_PAPERS,
          variables: {
            input: {
              ...input,
              nextToken,
            },
          },
        },
      })
    )?.data;

    return {
      ...data.data.getBuildPapers,
      success: !!data.data.getBuildPapers.data,
    };
  } catch (err) {
    console.error("getBuildPapers", err);
    if (data?.errors) {
      console.error("getBuildPapers", data?.errors);
    }
    return {
      success: false,
    };
  }
};

const getBuildPaper = async (
  id: string
): Promise<ServiceResponse<IPaper | undefined>> => {
  let data: any | undefined;

  const input: GetBuildPaperInput = {
    tenantId: TENANT_ID,
    id,
  };

  try {
    data = (
      await LmsBuildClient.request({
        method: "post",
        headers: {
          "x-api-key": LMS_API_KEY,
        },
        data: {
          query: GET_BUILD_PAPER,
          variables: {
            input,
          },
        },
      })
    )?.data;

    return {
      data: data.data.getBuildPaper,
      success: !!data.data.getBuildPaper,
    };
  } catch (err) {
    console.error("getBuildPaper", err, input);
    if (data?.errors) {
      console.error("getBuildPaper", data?.errors);
    }
    return {
      success: false,
    };
  }
};

const PaperService = { getBuildPapers, getBuildPaper };

export default {
  ...PaperService,
};
