import { TOPIC_FIELDS_FRAGMENT } from "./base/graphql";

export const GET_BUILD_TOPICS = /* GraphQL */ `
  ${TOPIC_FIELDS_FRAGMENT}
  query getBuildTopics($input: GetBuildTopicsInput!) {
    getBuildTopics(input: $input) {
      data {
        ...TopicFields
      }
      nextToken
    }
  }
`;
export const GET_BUILD_TOPIC = /* GraphQL */ `
  ${TOPIC_FIELDS_FRAGMENT}
  query getBuildTopic($input: GetBuildTopicInput!) {
    getBuildTopic(input: $input) {
      ...TopicFields
    }
  }
`;
