import { LMS_API_KEY, TENANT_ID } from "../../../config-global";
import { ServiceResponse } from "../../types";
import LmsBuildClient from "../../../../build/buildClient";
import base from "./base";
import { IWord } from "./base/types";
import { GET_BUILD_WORD, GET_BUILD_WORDS } from "./graphql";
import { GetBuildWordInput, GetBuildWordsInput } from "./types";

const getBuildWords = async (
    lastUpdated: string
): Promise<ServiceResponse<IWord[] | undefined>> => {
    let data: any | undefined;
    try {
        const input: GetBuildWordsInput = {
            tenantId: TENANT_ID,
            so: lastUpdated,
            limit: 1000
        };
        data = (
            await LmsBuildClient.request({
                method: "post",
                headers: {
                    "x-api-key": LMS_API_KEY,
                },
                data: {
                    query: GET_BUILD_WORDS,
                    variables: {
                        input,
                    },
                },
            })
        )?.data;

        return {
            ...data.data.getBuildWords,
            success: !!data.data.getBuildWords.data,
        };
    } catch (err) {
        console.error("getBuildWords", err);
        if (data?.errors) {
            console.error("getBuildWords", data?.errors);
        }
        return {
            success: false,
        };
    }
};

const getBuildWord = async (
    id: string
): Promise<ServiceResponse<IWord | undefined>> => {
    let data: any | undefined;

    const input: GetBuildWordInput = {
        tenantId: TENANT_ID,
        id,
    };

    try {


        data = (
            await LmsBuildClient.request({
                method: "post",
                headers: {
                    "x-api-key": LMS_API_KEY,
                },
                data: {
                    query: GET_BUILD_WORD,
                    variables: {
                        input,
                    },
                },
            })
        )?.data;

        return {
            data: data.data.getBuildWord,
            success: !!data.data.getBuildWord,
        };
    } catch (err) {
        console.error("getBuildWord", err, input);
        if (data?.errors) {
            console.error("getBuildWord", data?.errors);
        }
        return {
            success: false,
        };
    }
};

const WordService = { ...base, getBuildWords, getBuildWord };

export default {
    ...WordService,
};
