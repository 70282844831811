import _ from "lodash";
import React from "react";
import sanitize, { IOptions } from "sanitize-html";

import { useCourseContext } from "../../context/course";
import { usePartnerContext } from "../../context/partner";
import { IWord } from "../../services/lms/word/base/types";

const sanitizeConfig: IOptions = {
    allowedAttributes: { ...sanitize.defaults.allowedAttributes, "*": ["title", "style", "class"] },
    allowedTags: [...sanitize.defaults.allowedTags, "img"],
}

// TODO: HH: Store this highlight glossary words boolean on course if OF and GW like the feature
// OF: Good feature, I think maybe we can toggle this on and off in the course settings OR glossary settings??
// OF: also component needs ability to hard override this so that we can turn it off in the exam for example
const highlightGlossaryWords = false;

const SanitisedHTML = ({ text = "", hideClass = false }: { text?: string, hideClass?: boolean }) => {
    const { course } = useCourseContext();
    const { colors } = usePartnerContext();

    if (highlightGlossaryWords && course && course.glossary) {
        course?.glossary?.words?.forEach((word: IWord) => {

            const regex = new RegExp(` (${word.key.toLowerCase()})|(${_.startCase(word.key)}) `, "g");

            const matching = text.match(regex);
            const spanHtml = ` <span title="${word.definition}" style="color:${colors.primary};text-decoration:underline dotted;font-weight:bold;cursor:help;">
                ${matching?.[0] || word.key}
            </span> `;

            text = text.replace(regex, spanHtml);
        })
    }

    const updatedText = replaceOldSystemClassesAndTagsWithNew(text);
    return <div dangerouslySetInnerHTML={{ __html: sanitize(updatedText, sanitizeConfig) }} className={hideClass ? "sco-sanitise" : ""} />
}

const replaceOldSystemClassesAndTagsWithNew = (text: string) => {
    return text
        .replace(/col-(md|sm)-((1[012])|[1-9])/g, "w-$2/12") // e.g. col-md-6 to w-6/12
        .replace(/<h2>/g, "<h2 class='text-2xl font-bold mt-4'>") // e.g. <h2> to <h2 class='text-2xl font-bold'>
}

export default SanitisedHTML;