import Course from "./course/index";
import Module from "./module/index";
import Topic from "./topic/index";
import Asset from "./asset/index";
import StudentExam from "./studentExam/index";
import Paper from "./paper/index";
import Question from "./question/index";
import QuestionType from "./questionType/index";
import StudentExamPaper from "./studentExamPaper/index";
import StudentExamPaperAnswer from "./studentExamPaperAnswer/index";
import Exam from "./exam/index";
import Glossary from "./glossary/index";
import Word from "./word/index";
import Student from "./student/index";

const LmsService = {
  Course,
  Module,
  Topic,
  Asset,
  StudentExam,
  Paper,
  Question,
  QuestionType,
  StudentExamPaper,
  StudentExamPaperAnswer,
  Exam,
  Glossary,
  Word,
  Student
};

export default LmsService;
