
import QuestionClient from "../../client";
import CognitoService from "../../../cognito";
import { ServiceResponse } from "../../../types";
import {
  GET_QUESTION,
  CREATE_QUESTION,
  DELETE_QUESTION,
  UPDATE_QUESTION,
  GET_QUESTION_WITH,
  GET_BY_QUESTION_REF,

} from "./graphql";
import {
  IQuestion,
  IQuestionList,
  GetQuestionInput,
  CreateQuestionInput,
  UpdateQuestionInput,
  DeleteQuestionInput,
  GetByQuestionRefInput,

} from "./types";


export const getQuestion = async (input: GetQuestionInput): Promise<ServiceResponse<IQuestion>> => {
  let data: any | undefined;
  try {
    const token = await CognitoService.getToken();
    data = (
      await QuestionClient.request({
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          query: GET_QUESTION,
          variables: {
            input
          }
        }
      }))?.data;
    return {
      data: data.data.getQuestion,
      success: true,
    } as ServiceResponse<IQuestion>;
  } catch (err) {
    if (data?.data?.errors) {
      console.error('getQuestion', err);
    }
    return {
      success: false,
    } as ServiceResponse<IQuestion>;
  }
};

export const getQuestionWith = async (input: GetQuestionInput): Promise<ServiceResponse<IQuestion>> => {
  let data: any | undefined;
  try {
    const token = await CognitoService.getToken();
    data = (
      await QuestionClient.request({
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          query: GET_QUESTION_WITH,
          variables: {
            input
          }
        }
      }))?.data;
    return {
      data: data.data.getQuestionWith,
      success: true,
    } as ServiceResponse<IQuestion>;
  } catch (err) {
    if (data?.data?.errors) {
      console.error('getQuestion', err);
    }
    return {
      success: false,
    } as ServiceResponse<IQuestion>;
  }
};

export const createQuestion = async (input: CreateQuestionInput): Promise<ServiceResponse<IQuestion>> => {
  let data: any | undefined;;
  try {
    const token = await CognitoService.getToken();
    data = (
      await QuestionClient.request({
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`
        },
        data: {
          query: CREATE_QUESTION,
          variables: {
            input
          }
        }
      }))?.data;
    return {
      data: data.data.createQuestion,
      success: true,
    } as ServiceResponse<IQuestion>;
  } catch (err) {
    if (data?.data?.errors) {
      console.error('createQuestion', err);
    }
    return {
      success: false,
    } as ServiceResponse<IQuestion>;
  }
};

export const updateQuestion = async (input: UpdateQuestionInput): Promise<ServiceResponse<IQuestion>> => {
  let data: any | undefined;
  try {
    const token = await CognitoService.getToken();
    data = (
      await QuestionClient.request({
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`
        },
        data: {
          query: UPDATE_QUESTION,
          variables: {
            input
          }
        }
      }))?.data;
    return {
      data: data.data.updateQuestion,
      success: true,
    } as ServiceResponse<IQuestion>;
  } catch (err) {
    if (data?.data?.errors) {
      console.error('updateQuestion', err);
    }
    return {
      success: false,
    } as ServiceResponse<IQuestion>;
  }
};

export const deleteQuestion = async (input: DeleteQuestionInput): Promise<ServiceResponse<IQuestion>> => {
  let data: any | undefined;
  try {
    const token = await CognitoService.getToken();
    data = (
      await QuestionClient.request({
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`
        },
        data: {
          query: DELETE_QUESTION,
          variables: {
            input
          }
        }
      }))?.data;;
    return {
      data: data.data.deleteQuestion,
      success: true,
    } as ServiceResponse<IQuestion>;
  } catch (err) {
    if (data?.data?.errors) {
      console.error('deleteQuestion', err);
    }
    return {
      success: false,
    } as ServiceResponse<IQuestion>;
  }
};

export const getQuestionByRef = async (input: GetByQuestionRefInput): Promise<ServiceResponse<IQuestionList>> => {
  let data: any | undefined;
  try {
    const token = await CognitoService.getToken();
    data = (
      await QuestionClient.request({
        method: 'post',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          query: GET_BY_QUESTION_REF,
          variables: {
            input,
          },
        },
      })
    )?.data;
    return {
      ...data.data.getByQuestionRef,
      success: true,
    } as ServiceResponse<IQuestionList>;
  } catch (err) {
    if (data?.data?.errors) {
      console.error('GetByQuestionRefInput', err)
    }
    return {
      success: false,
    } as ServiceResponse<IQuestionList>;
  }
};



export default {
  getQuestion,
  getQuestionWith,
  createQuestion,
  updateQuestion,
  deleteQuestion,
  getQuestionByRef
};

