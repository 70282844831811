export const TENANT_ID = String(process.env.GATSBY_TENANT_ID);

export const PLATFORM_API = String(process.env.GATSBY_PLATFORM_API);
export const PLATFORM_API_KEY = String(process.env.GATSBY_PLATFORM_API_KEY);

export const SERVICE_DESK_API = String(process.env.GATSBY_SERVICE_DESK_API);
export const SERVICE_DESK_HOST = String(
  process.env.GATSBY_PUBLIC_SERVICE_DESK_HOST
);
export const SERVICE_DESK_WS = String(
  process.env.GATSBY_PUBLIC_SERVICE_DESK_WS
);

export const LMS_API = String(process.env.GATSBY_LMS_API);
export const LMS_API_KEY = String(process.env.GATSBY_LMS_API_KEY);

export const HEADLESS_CMS_API = String(process.env.GATSBY_HEADLESS_CMS_API);
export const HEADLESS_CMS_API_KEY = String(
  process.env.GATSBY_HEADLESS_CMS_API_KEY
);

export const FILE_MANAGER_API = String(process.env.GATSBY_FILE_MANAGER_API);
export const FILE_MANAGER_PRESIGNED_URL = String(
  process.env.GATSBY_FILE_MANAGER_PRESIGNED_URL
);

export const CHECKOUT_TENANT = String(process.env.GATSBY_CHECKOUT_TENANT);
export const CHECKOUT_API = String(process.env.GATSBY_CHECKOUT_API);
export const CHECKOUT_API_KEY = String(process.env.GATSBY_CHECKOUT_API_KEY);
