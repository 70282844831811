import React, { useEffect, ReactElement } from "react";

import UTIcon from "./icons";
import Button from "./button";
import { CardProps } from "./card";
import { usePartnerContext } from "../context/partner";

type ModalProps = {
    children?: ReactElement,
    show: boolean,
    header?: string,
    buttons?: ReactElement,
    color?: CardProps["color"],
    icon?: CardProps["icon"],
    padding?: string,
    modalPadding?: string,
    className?: string,
    onClose?: () => void,
    onConfirm?: () => void,
}

const Modal = ({ children, className = "", show, color, header = undefined, buttons = undefined, icon = undefined, padding = "py-5", modalPadding = "px-5", onClose = undefined, onConfirm = () => { } }: ModalProps) => {

    const { colors } = usePartnerContext();

    useEffect(() => {
        const value = show ? 'hidden' : 'auto';
        document.querySelector('body')?.setAttribute('style', `overflow: ${value};`);
        return () => {
            // set window overflow auto
            document.querySelector('body')?.setAttribute('style', 'overflow: auto;');
        }
    }, [show])

    if (!show) {
        return null;
    }

    return <div className={`fixed inset-0 z-50 bg-black bg-opacity-50 flex justify-center items-center`}>
        <div className={`bg-white rounded-md space-y-4 ${padding} ${className} overflow-auto max-h-[75vh] max-w-[75vw]`}
            style={{
                boxShadow: "0 5px 15px rgba(0, 0, 0, .25)"
            }}
        >
            {header && (
                <div className={"px-5"} style={{ color: colors.textColor }}>
                    <div className={"flex items-center justify-between space-x-3"}>
                        <div className={"flex items-center space-x-3"}>
                            {icon && (
                                <UTIcon color={colors.textColor} type={icon} />
                            )}
                            <h2 className={"text-2xl font-bold"}>
                                {header}
                            </h2>
                        </div>
                        <div>
                            {onClose && (
                                <Button onClick={onClose} padding={"px-2 py-1"} variant={"ghost"}>
                                    <UTIcon type={"close"} />
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            )}
            <div className={`${modalPadding}`}>
                {children}
            </div>
            {buttons && (
                <div className={"px-5"}>
                    {buttons}
                </div>
            )}
        </div>
    </div>
}

export default Modal;