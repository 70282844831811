import Base from "./base";
import CognitoService from "../../cognito";
import { ServiceResponse } from "../../types";
import StudentExamPaperClient from "../client";
import {
  LOAD_STUDENT_EXAM_PAPER,
  COMPLETE_STUDENT_EXAM_PAPER,
} from "./base/graphql";
import {
  IStudentExamPaper,
  ILoadStudentExamPaperInput,
  IUpdateStudentExamPaperInput,
  ILoadStudentExamPaperResponse,
} from "./base/types";

export const loadStudentExamPaper = async (
  input: ILoadStudentExamPaperInput
): Promise<ServiceResponse<ILoadStudentExamPaperResponse>> => {
  let data: any | undefined;
  const token = await CognitoService.getToken();
  if (!token) throw new Error("No token found");
  try {
    data = (
      await StudentExamPaperClient.request({
        method: "post",
        headers: {
          Authorization: `Bearer ${token} `,
        },
        data: {
          query: LOAD_STUDENT_EXAM_PAPER,
          variables: {
            input,
          },
        },
      })
    )?.data;
    return {
      data: data.data.loadStudentExamPaper,
      errors: data.errors,
      success: !data.errors?.length,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};
export const completeStudentExamPaper = async (
  input: IUpdateStudentExamPaperInput
): Promise<ServiceResponse<IStudentExamPaper | undefined>> => {
  let data: any | undefined;
  const token = await CognitoService.getToken();
  if (!token) throw new Error("No token found");
  try {
    data = (
      await StudentExamPaperClient.request({
        method: "post",
        headers: {
          Authorization: `Bearer ${token} `,
        },
        data: {
          query: COMPLETE_STUDENT_EXAM_PAPER,
          variables: {
            input,
          },
        },
      })
    )?.data;
    return {
      data: data.data.completeStudentExamPaper,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};

export const StudentExamPaperService = {
  completeStudentExamPaper,
  ...Base,
};

export default StudentExamPaperService;
