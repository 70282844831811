import React, { useEffect, useState } from 'react'
import AppHeader from './Header';
import { Link } from 'gatsby';
import { usePartnerContext } from '../../context/partner'
import { useCourseContext } from '../../context/course';
import _ from 'lodash';
import CourseProgress from '../../components/course/CourseProgress';
import UTIcon from '../../components/icons';
import AppNavigation from './Navigation';
import { useAuthContext } from '../../context/auth';

type Props = {
    children: React.ReactNode;
};

export default function AppLayout({ children }: Props) {

    const { colors } = usePartnerContext();

    return (
        <div
            className='flex flex-col'
            style={{
                minHeight: '100vh',
                backgroundColor: colors.backgroundGray,
                color: colors.textColor
            }}>
            <AppHeader />
            <div className='md:flex md:flex-1'>
                <AppNavigation />
                <main className="flex-1
                    pb-[60px]
                ">
                    {children}
                </main>
            </div>
        </div>
    )
}

