import { LMS_API_KEY, TENANT_ID } from "../../../config-global";
import CognitoService from "../../cognito";
import StudentClient from "../client";
import Base from "./base";
import {
  GetByEmailStudentInput,
  IStudent,
  IStudentAddress,
} from "./base/types";
import {
  DELETE_STUDENT_ADDRESS,
  GET_CREATE_STUDENT_ADDRESS,
  GET_MY_STUDENT,
  GET_STUDENT_EMAIL_WEBSITE,
} from "./base/graphql";
import { IDeleteStudentAddress, IGetCreateStudentAddressInput } from "./types";
import { ServiceResponse } from "../../types";

type GetUsernameByEmail = {
  cognitoUsername: string;
};

const getByStudentEmailWebsite = async (
  externalInput: Omit<GetByEmailStudentInput, "tenantId">
): Promise<GetUsernameByEmail | undefined> => {
  let data: any;
  try {
    const input: GetByEmailStudentInput = {
      ...externalInput,
      tenantId: TENANT_ID,
    };

    data = (
      await StudentClient.request({
        method: "post",
        headers: {
          "x-api-key": LMS_API_KEY,
        },
        data: {
          query: GET_STUDENT_EMAIL_WEBSITE,
          variables: {
            input,
          },
        },
      })
    )?.data;

    return data.data.getByStudentEmailWebsite;
  } catch (error) {
    console.error("student-service", "getByStudentEmailWebsite", error);
  }
  return undefined;
};
const getMyStudent = async (): Promise<IStudent | undefined> => {
  let data: any;
  try {
    const token = await CognitoService.getToken();

    data = (
      await StudentClient.request({
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          query: GET_MY_STUDENT,
        },
      })
    )?.data;

    return data.data.getMyStudent as IStudent;
  } catch (error) {
    console.error("student-service", "getMyStudent", error);
  }
  return undefined;
};
const getCreateStudentAddress = async (
  input: IGetCreateStudentAddressInput
): Promise<ServiceResponse<IStudentAddress>> => {
  let data: any;
  try {
    const token = await CognitoService.getToken();

    data = (
      await StudentClient.request({
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          query: GET_CREATE_STUDENT_ADDRESS,
          variables: {
            input,
          },
        },
      })
    )?.data;

    return {
      data: data.data.getCreateStudentAddress,
      success: true,
    };
  } catch (error) {
    console.error("student-service", "getMyStudent", error);
  }
  return {
    success: false,
  };
};
const deleteStudentAddress = async (
  input: IDeleteStudentAddress
): Promise<ServiceResponse<IStudentAddress>> => {
  let data: any;
  try {
    const token = await CognitoService.getToken();

    data = (
      await StudentClient.request({
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          query: DELETE_STUDENT_ADDRESS,
          variables: {
            input,
          },
        },
      })
    )?.data;

    return {
      data: data.data.deleteStudentAddress,
      success: true,
    };
  } catch (error) {
    console.error("student-service", "deleteStudentAddress", error);
  }
  return {
    success: false,
  };
};

const Student = {
  ...Base,
  getByStudentEmailWebsite,
  getMyStudent,
  getCreateStudentAddress,
  deleteStudentAddress,
};
export default Student;
