import _ from "lodash";
import Color from "color";

import { PartnerColors } from "../../../context/partner/types";

const shouldHighlight = (
  compareItemOne: string | undefined,
  compareItemTwo: string | undefined,
  colors: PartnerColors,
  highlightColor: keyof PartnerColors | undefined,
  reverseLogic = false
) => {
  // TODO: HH: For some reason referencing colors in partner context causes it to sometimes not load the colour, it puts the correct hex into the component
  // so in dev tools you can see it has the correct className, but it just doesn't have the colour, it's an odd behaviour. For now I'm just putting it into styles
  // instead of using tailwind classes

  const highlightColorReverseMap: Record<string, keyof PartnerColors> = {
    success: "danger",
    danger: "success",
    gray2: "gray2",
  };

  let highlight = {
    color: Color(colors.gray2).darken(0.2).hex(),
    name: "default",
  };

  if (
    compareItemTwo !== undefined &&
    compareItemOne !== undefined &&
    highlightColor !== undefined
  ) {
    const comparison = reverseLogic
      ? _.isEqual(compareItemTwo, compareItemOne)
      : !_.isEqual(compareItemTwo, compareItemOne);
    if (comparison) {
      highlight = {
        color: Color(colors[highlightColor]).darken(0.2).hex(),
        name: highlightColor,
      };
    } else {
      highlight = {
        color: Color(colors[highlightColorReverseMap[highlightColor]])
          .darken(0.2)
          .hex(),
        name: highlightColor,
      };
    }
  }

  return highlight;
};
export default shouldHighlight;
