import { LMS_API_KEY, TENANT_ID } from "../../../config-global";
import { ServiceResponse } from "../../types";
import LmsBuildClient from "../../../../build/buildClient";
import Base from "./base/index";
import { IModule } from "./base/types";
import { GET_BUILD_MODULE, GET_BUILD_MODULES } from "./graphql";
import { GetBuildModuleInput, GetBuildModulesInput } from "./types";

const getBuildModules = async (
  lastUpdated: string,
  nextToken?: string
): Promise<ServiceResponse<IModule[] | undefined>> => {
  let data: any | undefined;
  try {
    const input: GetBuildModulesInput = {
      tenantId: TENANT_ID,
      so: lastUpdated,
      limit: 1000,
    };
    data = (
      await LmsBuildClient.request({
        method: "post",
        headers: {
          "x-api-key": LMS_API_KEY,
        },
        data: {
          query: GET_BUILD_MODULES,
          variables: {
            input: { ...input, nextToken },
          },
        },
      })
    )?.data;

    return {
      ...data.data.getBuildModules,
      success: !!data.data.getBuildModules.data,
    };
  } catch (err) {
    console.error("getBuildModules", err);
    if (data?.errors) {
      console.error("getBuildModules", data?.errors);
    }
    return {
      success: false,
    };
  }
};

const getBuildModule = async (
  id: string
): Promise<ServiceResponse<IModule | undefined>> => {
  let data: any | undefined;
  try {
    const input: GetBuildModuleInput = {
      tenantId: TENANT_ID,
      id,
    };

    data = (
      await LmsBuildClient.request({
        method: "post",
        headers: {
          "x-api-key": LMS_API_KEY,
        },
        data: {
          query: GET_BUILD_MODULE,
          variables: {
            input,
          },
        },
      })
    )?.data;

    return {
      data: data.data.getBuildModule,
      success: !!data.data.getBuildModule,
    };
  } catch (err) {
    console.error("getBuildModule", err);
    if (data?.errors) {
      console.error("getBuildModule", data?.errors);
    }
    return {
      success: false,
    };
  }
};

const ModuleService = { getBuildModules, getBuildModule };

export default {
  ...Base,
  ...ModuleService,
};
