import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import React, { useMemo, useState, useEffect } from 'react'

import UTIcon from '../../components/icons';
import { useAuthContext } from '../../context/auth';
import useWindowSize from '../../hooks/useWindowSize';
import { useCourseContext } from '../../context/course';
import { usePartnerContext } from '../../context/partner';

const DEFAULT_ICON_SIZE = 35;
const SIDEBAR_ICON_SIZE = 18;

const DEFAULT_LINKS = [{
    label: 'Classroom',
    path: '/',
    icon: 'classroom'
}, {
    label: 'My Account',
    path: '/my-account/',
    icon: 'user'
}, {
    label: 'User Guide',
    path: '/help/user-guide/',
    icon: 'question'

}, {
    label: 'My Messages',
    path: '/my-messages/',
    icon: 'message'
}]

const COURSE_TREE_TOGGLE = false;

export default function AppNavigation() {
    const isBrowser = () => typeof window !== "undefined";

    if (!isBrowser()) return <></>;

    const { t } = useTranslation();
    const { colors } = usePartnerContext();
    const { course } = useCourseContext();

    const { isMobile } = useWindowSize();

    const { conversations } = useAuthContext();

    const [navLocations, setNavLocations] = useState<Record<string, any>>({});

    useEffect(() => {
        if (course) {
            const navLocationsUpdate = { ...navLocations }
            navLocationsUpdate[`/classroom/${course.slug}/`] = true;
            setNavLocations(navLocationsUpdate);
        }
    }, [course])

    // const activeCourse = !!navLocations[`/classroom/${course?.slug}/`];
    const activeCourse = COURSE_TREE_TOGGLE ? !!navLocations[`/classroom/${course?.slug}/`] : false;
    const courseDash = navLocations[`/classroom/${course?.slug}/`];

    const path = window?.location?.pathname;

    const accreditationImages = useMemo(() => {
        return course?.accreditationImages;
    }, [course?.accreditationImages])

    if (isMobile) return <></>;

    const unreadConversations = conversations?.filter((convo) => convo.fromUnread === true) || [];

    return (
        <>
            <aside className={"w-[220px]"}
                style={{
                    backgroundColor: colors.white,
                    borderRight: `3px solid ${colors.gray2}`
                }}
            >
                {!activeCourse && (
                    <div className={""}>
                        {courseDash && <Link to={`/classroom/${course?.slug}`} className={"block px-3 py-2 border-b-2"}>
                            <div className={"flex items-center space-x-3"}>
                                <UTIcon
                                    className={"p-1"}
                                    size={DEFAULT_ICON_SIZE}
                                    type={"course"}
                                    color={path === `/classroom/${course?.slug}/` ? colors.primary : colors.gray8}
                                />
                                <span
                                    style={{
                                        color: path === `/classroom/${course?.slug}/` ? colors.primary : colors.gray8
                                    }}
                                >
                                    {t("dashboard")}
                                </span>
                            </div>
                        </Link>}
                        {DEFAULT_LINKS.map(link => (
                            <Link key={`NAV_LINK_${link.path}`} className={"block px-3 py-2 border-b-2"} to={link.path}>
                                <div className={"flex items-center space-x-3"}>
                                    <UTIcon
                                        className={"p-1"}
                                        size={DEFAULT_ICON_SIZE}
                                        type={link.icon as any}
                                        color={path === link.path ? colors.primary : colors.gray8}
                                    />
                                    <span
                                        style={{
                                            color: path === link.path ? colors.primary : colors.gray8
                                        }}
                                    >
                                        {link.label}
                                    </span>
                                    {link.label === 'My Messages' && unreadConversations.length > 0 && <div style={{ backgroundColor: colors.primary, color: colors.primaryText }} className={"text-center font-bold text-sm rounded-full w-[22px] h-[22px]"}>
                                        {unreadConversations.length}
                                    </div>}
                                </div>
                            </Link>
                        ))}
                    </div>
                )}
                {/* Course Nav */}
                {activeCourse && <CourseNav course={course} navLocations={navLocations} setNavLocations={setNavLocations} />}
                {accreditationImages && (
                    <div className={"space-y-3"}>
                        {accreditationImages?.map((image) => (
                            <div key={`NAV_ACCRED_${image.id}`} className={"px-3 py-2"}>
                                <img src={image.url} alt={image.name} />
                            </div>
                        ))}
                    </div>
                )}
            </aside>
        </>
    )
}

const CourseNav = ({ course, navLocations, setNavLocations }: { course: any, navLocations: Record<string, any>, setNavLocations: any }) => {

    const { colors } = usePartnerContext();

    return <>
        <div className={"px-5 py-3 block items-center border-b"}>
            <Link to={`/classroom/${course?.slug}`}><p className={"text-xl"}>{course?.name}</p></Link>
            <div className={"text-md"}>{course?.modules?.length || 0} Modules
            </div>
            {/* OF: REMOVED AS DIDN'T LOOK RIGHT */}
            {/* <div className='w-full'>
                <CourseProgress minified />
            </div> */}
        </div>
        {course?.modules?.map((module: any) => {
            const activeModule = !!navLocations[`/classroom/${course.slug}/${module.slug}/`];
            return <div className={"text-sm"}>
                <div className={"px-2 py-1 flex justify-between items-center border-b"}>
                    {/* HH: Render module */}
                    <Link to={`/classroom/${course.slug}/${module.slug}/`} className={"flex items-center"}>
                        <UTIcon type={"module"} size={SIDEBAR_ICON_SIZE} className={"mr-2"} color={document.location.pathname.match(`/classroom/${course.slug}/${module.slug}/`) ? colors.primary : colors.gray8} />
                        <span className={"w-full"}>{module?.shortName || module?.name}</span>
                    </Link>
                    <div className={"caret cursor-pointer"} onClick={() => {
                        if (!activeModule) {
                            const navLocationsUpdate = { ...navLocations }
                            navLocationsUpdate[`/classroom/${course.slug}/${module.slug}/`] = true;
                            setNavLocations(navLocationsUpdate);
                        } else {
                            const navLocationsUpdate = { ...navLocations }
                            delete navLocationsUpdate[`/classroom/${course.slug}/${module.slug}/`]
                            setNavLocations(navLocationsUpdate);
                        }
                    }}>
                        <UTIcon type={activeModule ? "minus" : "plus"} className={"mr-1"} size={SIDEBAR_ICON_SIZE} />
                    </div>
                </div>
                {/* HH: Render topics on module */}
                {activeModule && module?.topics?.map((topic: any) => {
                    const activeTopic = !!navLocations[`/classroom/${course.slug}/${module.slug}/${topic.slug}/`];
                    return <>
                        <div className={"px-2 py-1 flex justify-between items-center border-b ml-4"}>
                            <Link to={`/classroom/${course.slug}/${module.slug}/${topic.slug}/`} className={"flex items-center"}>
                                <UTIcon type={"topic"} size={SIDEBAR_ICON_SIZE} className={"mr-1"} color={document.location.pathname.match(`/classroom/${course.slug}/${module.slug}/${topic.slug}/`) ? colors.primary : colors.gray8} />
                                <span className={"w-full"}>  {topic?.name}</span>
                            </Link>
                            {!!topic?.subTopics?.length && <div className={"caret cursor-pointer"} onClick={() => {
                                if (!activeTopic) {
                                    const navLocationsUpdate = { ...navLocations }
                                    navLocationsUpdate[`/classroom/${course.slug}/${module.slug}/${topic.slug}/`] = true;
                                    setNavLocations(navLocationsUpdate);
                                } else {
                                    const navLocationsUpdate = { ...navLocations }
                                    delete navLocationsUpdate[`/classroom/${course.slug}/${module.slug}/${topic.slug}/`]
                                    setNavLocations(navLocationsUpdate);
                                }
                            }}>
                                <UTIcon type={activeTopic ? "minus" : "plus"} className={"mr-1"} size={SIDEBAR_ICON_SIZE} />
                            </div>}
                        </div>
                        {/* HH: Render subtopics on topic */}
                        {activeTopic && !!topic?.subTopics?.length && topic.subTopics.map((subTopic: any) => {
                            return <div className={"px-2 py-1 flex items-center border-b ml-8"}>
                                <Link to={`/classroom/${course.slug}/${module.slug}/${topic.slug}/${subTopic.slug}`} className={"flex"}>
                                    <UTIcon type={"topic"} size={SIDEBAR_ICON_SIZE} className={"mr-2"} color={document.location.pathname.match(`/classroom/${course.slug}/${module.slug}/${topic.slug}/${subTopic.slug}`) ? colors.primary : colors.gray8} />
                                    <span className={"w-full"}>  {subTopic?.name}</span>
                                </Link>
                            </div>
                        })}
                        {/* HH: Render summary on parent topics */}
                        {activeTopic && !!topic?.subTopics?.length && <div className={"px-2 py-1 flex items-center border-b ml-8"}>
                            <Link to={`/classroom/${course.slug}/${module.slug}/${topic.slug}/summary`} className={"flex"}>
                                <UTIcon type={"topic"} size={SIDEBAR_ICON_SIZE} className={"mr-1"} color={document.location.pathname.match(`/classroom/${course.slug}/${module.slug}/${topic.slug}/summary`) ? colors.primary : colors.gray8} />
                                <span className={"w-full"}>Summary</span>
                            </Link>
                        </div>
                        }
                    </>
                })}
                {/* HH: Render module test */}
                {activeModule && <div className={"px-2 py-1 flex items-center border-b ml-4"}>
                    <Link to={`/classroom/${course.slug}/${module.slug}/module-test`} className={"flex"}>
                        <UTIcon type={"topic"} size={SIDEBAR_ICON_SIZE} className={"mr-1"} color={document.location.pathname.match(`/classroom/${course.slug}/${module.slug}/module-test`) ? colors.primary : colors.gray8} />
                        <span className={"w-full"}>Module Test</span>
                    </Link>
                </div>}
            </div>
        })}
    </>

};

