import Color from 'color';
import { Link } from 'gatsby';
import React, { useMemo, ReactElement } from 'react'

import UTIcon, { UTIconProps } from './icons';
import { usePartnerContext } from '../context/partner'
import { PartnerColors } from '../context/partner/types';

export type CardProps = {
    children?: React.ReactNode
    padding?: string
    cardClass?: string
    className?: string
    heading?: string
    headingSize?: number,
    headingPadding?: string,
    icon?: UTIconProps["type"],
    iconSize?: number,
    iconExtra?: UTIconProps["type"],
    iconColor?: keyof PartnerColors,
    color?: "grey" | "grey2" | "info" | "info2" | "warning" | "warning2" | "danger" | "danger2" | "success" | "success2" | "primary" | "primary2"
    overrideColors?: React.CSSProperties
    style?: React.CSSProperties
    link?: string
    linkDisabled?: boolean
    right?: React.ReactNode,
    footerButtons?: ReactElement,
    close?: () => void,
    accordion?: boolean
}

export default function Card({
    children,
    style,
    link,
    linkDisabled,
    className = "",
    cardClass = "",
    color,
    heading,
    headingSize = 25,
    headingPadding = "p-3",
    overrideColors = {},
    padding = "p-5",
    icon = undefined,
    iconSize = 23,
    iconExtra = undefined,
    iconColor,
    right,
    footerButtons,
    close,
    accordion = false
}: CardProps) {
    const [accordionOpen, setAccordionOpen] = React.useState<boolean>(false);

    const { colors } = usePartnerContext();

    const colorStyle: any = useMemo(() => {
        let borderColor = Color(colors.gray1).darken(0.2).hex();
        let backgroundColor = colors.white;
        let headerColor = {
            backgroundColor: colors.gray1,
            color: colors.textColor,
            borderColor: Color(colors.gray1).darken(0.2).hex()
        };
        let bodyColor = colors.textColor;
        let headingBorderColor = colors.gray6;

        if (color === "grey") {
            borderColor = Color(colors.gray1).darken(0.2).hex()
            headerColor = {
                backgroundColor: colors.gray1,
                color: colors.textColor,
                borderColor: Color(colors.gray1).darken(0.2).hex()
            };
        }

        if (color === "grey2") {
            backgroundColor = colors.gray1
        }

        if (color === "info") {
            borderColor = Color(colors.info).darken(0.2).hex();
            headerColor = {
                backgroundColor: colors.info,
                color: colors.textColor,
                borderColor: Color(colors.info).darken(0.2).hex()
            };
        }

        if (color === "info2") {
            borderColor = Color(colors.info).darken(0.2).hex();
            backgroundColor = Color(colors.info).lighten(0).hex();
            headerColor = {
                backgroundColor: Color(colors.info).hex(),
                color: colors.textColor,
                borderColor: Color(colors.info).darken(0.2).hex()
            };
            bodyColor = Color(colors.info).darken(0.65).hex();
        }

        if (color === "success") {
            borderColor = Color(colors.success).darken(0.2).hex();
            headerColor = {
                backgroundColor: colors.success,
                color: colors.successText,
                borderColor: Color(colors.success).darken(0.2).hex()
            };
        }

        if (color === "success2") {
            borderColor = Color(colors.success).darken(0.2).hex();
            backgroundColor = Color(colors.success).hex();
            bodyColor = colors.successText;
            headerColor = {
                backgroundColor: colors.success,
                color: colors.successText,
                borderColor: Color(colors.success).darken(0.2).hex()
            };
        }

        if (color === "warning") {
            borderColor = Color(colors.warning).darken(0.2).hex();
            headerColor = {
                backgroundColor: colors.warning,
                color: colors.warningText,
                borderColor: Color(colors.warning).darken(0.2).hex()
            };
            headingBorderColor = colors.warningText;
        }

        if (color === "warning2") {
            borderColor = Color(colors.warning).darken(0.2).hex();
            backgroundColor = colors.warning;
            bodyColor = colors.warningText;
            headerColor = {
                backgroundColor: colors.warning,
                color: colors.warningText,
                borderColor: Color(colors.warning).darken(0.2).hex()
            };
            headingBorderColor = colors.warningText;
        }

        if (color === "danger") {
            borderColor = Color(colors.danger).darken(0.2).hex();
            bodyColor = colors.textColor;
            headerColor = {
                backgroundColor: colors.danger,
                color: colors.dangerText,
                borderColor: Color(colors.danger).darken(0.2).hex()
            };
        }

        if (color === "danger2") {
            borderColor = Color(colors.danger).darken(0.2).hex();
            backgroundColor = Color(colors.danger).lighten(0.5).hex();
            headerColor = {
                backgroundColor: colors.danger,
                color: colors.dangerText,
                borderColor: Color(colors.danger).darken(0.2).hex()
            };
        }

        if (color === "primary") {
            borderColor = Color(colors.primary).darken(0.2).hex();
            headerColor = {
                backgroundColor: colors.primary,
                color: colors.textColor,
                borderColor: Color(colors.primary).darken(0.2).hex()
            };
            headingBorderColor = colors.primaryText;
        }

        if (color === "primary2") {
            borderColor = Color(colors.primary).darken(0.2).hex();
            backgroundColor = colors.primary;
            bodyColor = colors.primaryText;
            headerColor = {
                backgroundColor: colors.primary,
                color: colors.primaryText,
                borderColor: Color(colors.primary).darken(0.2).hex()
            };
            headingBorderColor = colors.primaryText;
        }

        return {
            borderColor,
            backgroundColor,
            headerColor,
            bodyColor,
            headingBorderColor
        }
    }, [color, colors])

    const card = <div className={`${cardClass}`}
        style={{
            ...{
                borderColor: colorStyle.borderColor as any,
                backgroundColor: colorStyle.backgroundColor,
                color: colorStyle.bodyColor,
                // boxShadow: `0 1px 2px 1px ${colors.gray3}`,
                boxShadow: `0 1px 2px 1px rgba(0,0,0,0.15)`
            },
            ...overrideColors,
            ...style
        }}
    >
        {(heading || right) && <h2
            className={`flex text-lg font-bold ${headingPadding} ${(!accordion || (accordion && accordionOpen)) && children && "border-b"} ${accordion && "cursor-pointer"}`}
            style={{ ...colorStyle.headerColor }}
            onClick={() => accordion && setAccordionOpen(!accordionOpen)}
        >
            <div className={"flex items-center"}>
                {icon && <span className={"mr-3 pr-3 border-r"} style={{ borderColor: colorStyle.headingBorderColor }}><UTIcon type={icon} extra={iconExtra} color={iconColor ? colors[iconColor] : colorStyle.headerColor} size={iconSize} /></span>}
                <span style={{ fontSize: headingSize }}>
                    {heading}
                </span>
            </div>
            <div className={"flex-1"}>
                {right}
            </div>
            <div className={"flex justify-end items-center"}>
                {close && <span className={"cursor-pointer"} onClick={close}><UTIcon type={"close"} color={iconColor ? colors[iconColor] : colorStyle.headerColor} size={20} /></span>}
                {accordion && <span className={"cursor-pointer"} onClick={() => { setAccordionOpen(!accordionOpen) }}><UTIcon type={accordionOpen ? "minus" : "plus"} color={"black"} size={20} /></span>}
            </div>
        </h2>}
        {(!accordion || (accordion && accordionOpen)) && children && <><div className={`${padding} ${className}`}>
            {children}
        </div>
            {footerButtons && <div className={"flex justify-end p-3"}>
                {footerButtons}
            </div>}
        </>}
    </div>

    if (link && !linkDisabled) {
        return <Link to={link}>
            {card}
        </Link>
    }

    return card;
}
