import CognitoService from '../../../cognito';
import { ServiceResponse } from '../../../types';
import StudentExamPaperAnswerClient from '../../client';
import { IStudentExamPaperAnswer, IGetStudentExamPaperAnswerInput, IGetStudentExamPaperAnswersInput, ICreateStudentExamPaperAnswerInput, IUpdateStudentExamPaperAnswerInput, IDeleteStudentExamPaperAnswerInput } from './types';
import { GET_STUDENT_EXAM_PAPER_ANSWER, GET_STUDENT_EXAM_PAPER_ANSWERS, CREATE_STUDENT_EXAM_PAPER_ANSWER, UPDATE_STUDENT_EXAM_PAPER_ANSWER, DELETE_STUDENT_EXAM_PAPER_ANSWER, GET_STUDENT_EXAM_PAPER_ANSWER_WITH } from './graphql';

export const createStudentExamPaperAnswer = async (
  input: ICreateStudentExamPaperAnswerInput
): Promise<ServiceResponse<IStudentExamPaperAnswer | undefined>> => {
  let data: any | undefined;
  const token = await CognitoService.getToken();
  if (!token) throw new Error('No token found');
  try {
    data = (
      await StudentExamPaperAnswerClient.request({
        method: 'post',
        headers: {
          Authorization: `Bearer ${token} `,
        },
        data: {
          query: CREATE_STUDENT_EXAM_PAPER_ANSWER,
          variables: {
            input
          },
        },
      })
    )?.data;
    return {
      data: data.data.createStudentExamPaperAnswer,
      success: true,
    }
  } catch (err) {
    return {
      success: false,
    };
  }
};
export const getStudentExamPaperAnswer = async (
  input: IGetStudentExamPaperAnswerInput
): Promise<ServiceResponse<IStudentExamPaperAnswer | undefined>> => {
  let data: any | undefined;
  const token = await CognitoService.getToken();
  if (!token) throw new Error('No token found');
  try {
    data = (
      await StudentExamPaperAnswerClient.request({
        method: 'post',
        headers: {
          Authorization: `Bearer ${token} `,
        },
        data: {
          query: GET_STUDENT_EXAM_PAPER_ANSWER,
          variables: {
            input
          },
        },
      })
    )?.data;
    return {
      data: data.data.getStudentExamPaperAnswer,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};
export const getStudentExamPaperAnswers = async (
  input: IGetStudentExamPaperAnswersInput
): Promise<ServiceResponse<IStudentExamPaperAnswer[] | undefined>> => {
  let data: any | undefined;
  const token = await CognitoService.getToken();
  if (!token) throw new Error('No token found');
  try {
    data = (
      await StudentExamPaperAnswerClient.request({
        method: 'post',
        headers: {
          Authorization: `Bearer ${token} `,
        },
        data: {
          query: GET_STUDENT_EXAM_PAPER_ANSWERS,
          variables: {
            input
          },
        },
      })
    )?.data;
    return {
      ...data.data.getStudentExamPaperAnswers,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};
export const getStudentExamPaperAnswerWith = async (
  input: IGetStudentExamPaperAnswerInput
): Promise<ServiceResponse<IStudentExamPaperAnswer[] | undefined>> => {
  let data: any | undefined;
  const token = await CognitoService.getToken();
  if (!token) throw new Error('No token found');
  try {
    data = (
      await StudentExamPaperAnswerClient.request({
        method: 'post',
        headers: {
          Authorization: `Bearer ${token} `,
        },
        data: {
          query: GET_STUDENT_EXAM_PAPER_ANSWER_WITH,
          variables: {
            input
          },
        },
      })
    )?.data;
    return {
      data: data.data.getStudentExamPaperAnswerWith,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};
export const updateStudentExamPaperAnswer = async (
  input: IUpdateStudentExamPaperAnswerInput
): Promise<ServiceResponse<IStudentExamPaperAnswer | undefined>> => {
  let data: any | undefined;
  const token = await CognitoService.getToken();
  if (!token) throw new Error('No token found');
  try {
    data = (
      await StudentExamPaperAnswerClient.request({
        method: 'post',
        headers: {
          Authorization: `Bearer ${token} `,
        },
        data: {
          query: UPDATE_STUDENT_EXAM_PAPER_ANSWER,
          variables: {
            input
          },
        },
      })
    )?.data;
    return {
      data: data.data.updateStudentExamPaperAnswer,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};
export const deleteStudentExamPaperAnswer = async (
  input: IDeleteStudentExamPaperAnswerInput
): Promise<ServiceResponse<IStudentExamPaperAnswer | undefined>> => {
  let data: any | undefined;
  const token = await CognitoService.getToken();
  if (!token) throw new Error('No token found');
  try {
    data = (
      await StudentExamPaperAnswerClient.request({
        method: 'post',
        headers: {
          Authorization: `Bearer ${token} `,
        },
        data: {
          query: DELETE_STUDENT_EXAM_PAPER_ANSWER,
          variables: {
            input
          },
        },
      })
    )?.data;
    return {
      data: data.data.deleteStudentExamPaperAnswer,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};
const PlatformService = {
  createStudentExamPaperAnswer,
  getStudentExamPaperAnswers,
  getStudentExamPaperAnswerWith,
  getStudentExamPaperAnswer,
  updateStudentExamPaperAnswer,
  deleteStudentExamPaperAnswer,
};
export default {
  ...PlatformService,
};