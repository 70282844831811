import GlossaryClient from '../../client';
import CognitoService from '../../../cognito';
import { ServiceResponse } from '../../../types';
import { GET_GLOSSARY, CREATE_GLOSSARY, UPDATE_GLOSSARY, DELETE_GLOSSARY, GET_GLOSSARY_WITH, GET_ALL_GLOSSARIES } from './graphql';
import { IGlossary, IGetGlossaryInput, ICreateGlossaryInput, IUpdateGlossaryInput, IDeleteGlossaryInput, IGetAllGlossariesInput } from './types';

export const createGlossary = async (
  input: ICreateGlossaryInput
): Promise<ServiceResponse<IGlossary | undefined>> => {
  let data: any | undefined;
  const token = await CognitoService.getToken();
  if (!token) throw new Error('No token found');
  try {
    data = (
      await GlossaryClient.request({
        method: 'post',
        headers: {
          Authorization: `Bearer ${token} `,
        },
        data: {
          query: CREATE_GLOSSARY,
          variables: {
            input
          },
        },
      })
    )?.data;
    return {
      data: data.data.createGlossary,
      success: true,
    }
  } catch (err) {
    return {
      success: false,
    };
  }
};
export const getGlossary = async (
  input: IGetGlossaryInput
): Promise<ServiceResponse<IGlossary | undefined>> => {
  let data: any | undefined;
  const token = await CognitoService.getToken();
  if (!token) throw new Error('No token found');
  try {
    data = (
      await GlossaryClient.request({
        method: 'post',
        headers: {
          Authorization: `Bearer ${token} `,
        },
        data: {
          query: GET_GLOSSARY,
          variables: {
            input
          },
        },
      })
    )?.data;
    return {
      data: data.data.getGlossary,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};
export const getAllGlossaries = async (
  input: IGetAllGlossariesInput
): Promise<ServiceResponse<IGlossary[] | undefined>> => {
  let data: any | undefined;
  const token = await CognitoService.getToken();
  if (!token) throw new Error('No token found');
  try {
    data = (
      await GlossaryClient.request({
        method: 'post',
        headers: {
          Authorization: `Bearer ${token} `,
        },
        data: {
          query: GET_ALL_GLOSSARIES,
          variables: {
            input
          },
        },
      })
    )?.data;
    return {
      ...data.data.getGlossaries,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};
export const getGlossaryWith = async (
  input: IGetGlossaryInput
): Promise<ServiceResponse<IGlossary | undefined>> => {
  let data: any | undefined;
  const token = await CognitoService.getToken();
  if (!token) throw new Error('No token found');
  try {
    data = (
      await GlossaryClient.request({
        method: 'post',
        headers: {
          Authorization: `Bearer ${token} `,
        },
        data: {
          query: GET_GLOSSARY_WITH,
          variables: {
            input
          },
        },
      })
    )?.data;
    return {
      data: data.data.getGlossaryWith,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};
export const updateGlossary = async (
  input: IUpdateGlossaryInput
): Promise<ServiceResponse<IGlossary | undefined>> => {
  let data: any | undefined;
  const token = await CognitoService.getToken();
  if (!token) throw new Error('No token found');
  try {
    data = (
      await GlossaryClient.request({
        method: 'post',
        headers: {
          Authorization: `Bearer ${token} `,
        },
        data: {
          query: UPDATE_GLOSSARY,
          variables: {
            input
          },
        },
      })
    )?.data;
    return {
      data: data.data.updateGlossary,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};
export const deleteGlossary = async (
  input: IDeleteGlossaryInput
): Promise<ServiceResponse<IGlossary | undefined>> => {
  let data: any | undefined;
  const token = await CognitoService.getToken();
  if (!token) throw new Error('No token found');
  try {
    data = (
      await GlossaryClient.request({
        method: 'post',
        headers: {
          Authorization: `Bearer ${token} `,
        },
        data: {
          query: DELETE_GLOSSARY,
          variables: {
            input
          },
        },
      })
    )?.data;
    return {
      data: data.data.deleteGlossary,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};
const PlatformService = {
  createGlossary,
  getGlossaryWith,
  getGlossary,
  updateGlossary,
  deleteGlossary,
};
export default {
  ...PlatformService,
};