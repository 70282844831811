import React from 'react'

import './i18n';
import AppLayout from './app/Layout';
import { AuthProvider } from '../context/auth'
import DevTools from '../components/devtools';
import { CourseProvider } from '../context/course';
import { PartnerProvider } from '../context/partner';
import { MessageProvider } from '../context/message';
import { PartnerGuard } from '../context/partner/guard';
import { ServiceDeskProvider } from '../context/serviceDesk';
import { ServiceDeskMessageProvider } from '../context/serviceDesk/Message';
import { ServiceDeskConversationProvider } from '../context/serviceDesk/Conversation';

type Props = {
  children: React.ReactNode;
  wrapperProps?: any;
};

export default function GlobalLayout({ children, wrapperProps }: Props) {
  const isDevMode = process.env.GATSBY_DEV_TOOLS_ENBALED === "true";
  return (
    <PartnerProvider>
      <PartnerGuard>
        <MessageProvider wrapperProps={wrapperProps}>
          <AuthProvider>
            <CourseProvider wrapperProps={wrapperProps}>
              <ServiceDeskProvider>
                <ServiceDeskConversationProvider>
                  <ServiceDeskMessageProvider>
                    <AppLayout>
                      {children}
                      {isDevMode && <DevTools />}
                    </AppLayout>
                  </ServiceDeskMessageProvider>
                </ServiceDeskConversationProvider>
              </ServiceDeskProvider>
            </CourseProvider>
          </AuthProvider>
        </MessageProvider>
      </PartnerGuard>
    </PartnerProvider>
  )
}
