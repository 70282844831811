'use client';

import useWebSocket, { ReadyState } from 'react-use-websocket';
import React, { useMemo, useEffect, useContext, useCallback, createContext } from 'react';

import { useAuthContext } from '../auth';
import CognitoService from '../../services/cognito';
import { TENANT_ID, SERVICE_DESK_WS, SERVICE_DESK_HOST } from '../../config-global';

type ServiceDeskContextType = {
    lastJsonMessage: any;
    sendMessage: (message: string) => void;
};

// CONTEXT
export const ServiceDeskContext = createContext({} as ServiceDeskContextType);

// PROVIDER
type Props = {
    children: React.ReactNode;
};

export function ServiceDeskProvider({ children }: Props) {
    const url = useCallback(async () => {
        const token = await CognitoService.getToken();
        // Header
        const header = {
            host: SERVICE_DESK_HOST,
            Authorization: `Bearer ${token}`,
        };
        const btoaHeader = btoa(JSON.stringify(header));
        const btoaPayload = btoa(JSON.stringify({}));
        return `${SERVICE_DESK_WS}?header=${btoaHeader}&payload=${btoaPayload}`;
    }, []);

    const tenant = TENANT_ID;

    const { lastJsonMessage, sendMessage, readyState } = useWebSocket<any>(url, { protocols: 'graphql-ws', });

    const { student } = useAuthContext();
    // Handle Connection
    useEffect(() => {
        const init = async () => {
            if (!tenant || !student?.id) {
                return;
            }
            if (readyState === ReadyState.OPEN) {
                sendMessage(JSON.stringify({
                    type: 'connection_init'
                }));
            }
        };
        init();
    }, [tenant, student?.id, readyState, sendMessage]);

    // Handle Messages
    useEffect(() => {
        const handle = async () => {
            if (!lastJsonMessage) return;
            if (!tenant || !student?.id) return;
            const type = lastJsonMessage?.type;

            // CONNECTION ACKNOWLEDGEMENT
            if (type === "connection_ack") {
                console.log("Connection Acknowledged");
            };
        }


        handle();
    }, [lastJsonMessage, tenant, student?.id, sendMessage]);

    const memoizedValue = useMemo(
        () => ({
            lastJsonMessage,
            sendMessage,
        }),
        [lastJsonMessage, sendMessage]
    );

    return <ServiceDeskContext.Provider value={memoizedValue}>{children}</ServiceDeskContext.Provider>;
}

// HOOK
export const useServiceDeskContext = () => {
    const context = useContext(ServiceDeskContext)
    if (!context)
        throw new Error("useServiceDeskContext context must be use inside ServiceDeskProvider");
    return context;
};

